import React, { ReactNode } from 'react';
import { PopUp, PopupProps, useFindComponentByType } from 'src/components-dummy';
import { PopUpStyled, PopUpWithOffsetStyled } from './AddNewTagsPopUp.styles';

export interface WithChildrenProps {
  children: ReactNode[] | ReactNode;
}

export const PopUpTrigger = ({ children }: WithChildrenProps): JSX.Element => <>{children}</>;
export const PopUpContent = ({ children }: WithChildrenProps): JSX.Element => <>{children}</>;

export interface PopUpWithOffsetProps extends Pick<PopupProps, 'hoverable' | 'triggerEvent'> {
  triggerWidth: number;
  triggerHeight: number;
  offsetTop: number;
  offsetLeft: number;
  show: boolean;
  closeOnClickOutside: boolean;
  onPopupShow: (show: boolean) => void;
  children: JSX.Element | JSX.Element[];
  isAIDetected: boolean;
}

export const PopUpWithOffset = ({
  triggerWidth,
  triggerHeight,
  offsetTop,
  offsetLeft,
  show,
  onPopupShow,
  children,
  closeOnClickOutside,
  hoverable,
  triggerEvent,
  isAIDetected,
}: PopUpWithOffsetProps): JSX.Element => {
  const trigger = useFindComponentByType({
    type: PopUpTrigger,
    children,
  });

  const content = useFindComponentByType({
    type: PopUpContent,
    children,
  });

  return (
    <PopUpWithOffsetStyled
      top={offsetTop}
      left={offsetLeft}
      width={triggerWidth}
      height={triggerHeight}
    >
      <PopUpStyled
        show={show}
        hideOnScroll={false}
        position='bottom center'
        closeOnClickOutside={closeOnClickOutside}
        closeOnContentClick={false}
        closeOnTriggerClick={false}
        triggerEvent={triggerEvent}
        hoverable={hoverable}
        contentClickPreventDefault
        onShow={onPopupShow}
        isAIDetected={isAIDetected}
      >
        <PopUp.Trigger>{trigger}</PopUp.Trigger>
        <PopUp.Content>{content}</PopUp.Content>
      </PopUpStyled>
    </PopUpWithOffsetStyled>
  );
};

PopUpWithOffset.Trigger = PopUpTrigger;
PopUpWithOffset.Content = PopUpContent;
