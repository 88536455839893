import Joi, { StringSchema } from 'joi';

export const emailValidationSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
  .messages({ 'string.empty': 'Email can not be empty', 'string.email': 'Email is invalid' });

interface PasswordConfig {
  minLength: number;
  maxLength: number;
  schema: StringSchema;
}

export const passwordV2: PasswordConfig = {
  minLength: 14,
  maxLength: 200,
  schema: Joi.string(),
};

passwordV2.schema = passwordV2.schema
  .trim()
  .min(passwordV2.minLength)
  .max(passwordV2.maxLength)
  .required()
  .pattern(/^.*[A-Z].*$/)
  .message('Needs to include at least one uppercase character')
  .pattern(/^.*[a-z].*$/)
  .message('Needs to include at least one lowercase character')
  .pattern(/^.*\W.*$/)
  .message('Needs to include at least one special character')
  .pattern(/^.*\d.*$/)
  .message('Needs to include at least one number')
  .messages({
    'string.empty': 'Incorrect password',
    'string.min': `Incorrect password`,
    'string.max': `Incorrect password`,
  });

export const passwordV1: PasswordConfig = {
  minLength: 6,
  maxLength: 30,
  schema: Joi.string(),
};

passwordV1.schema = passwordV1.schema
  .trim()
  .min(passwordV1.minLength)
  .max(passwordV2.maxLength)
  .required()
  .messages({
    'any.required': 'Password is required',
    'string.empty': 'Password is required',
    'string.min': `Password is too short`,
    'string.max': `Password is too long`,
  });
