import { passwordV2 } from '../constants';

export const validationIndications = [
  {
    types: ['string.min', 'string.empty', 'string.max'],
    message: `New password must be between ${passwordV2.minLength}-${passwordV2.maxLength} characters`,
  },
  {
    types: ['string.pattern.base'],
    message: `Includes at least one number, lowercase, uppercase and special characters`,
  },
];
