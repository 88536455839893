import { resetPasswordActions } from 'src/containers';
import { ToastTypesRegex } from './types';
import { toastMiddlewareActions } from 'src/middleware-actions';

export const toastTypesRegex: ToastTypesRegex = {
  error: /error|notfound|rejected|ErrorNotification/i,
  success: /Success|fulfilled|Notification/i,
  toastNotification: /CreateToast/i,
};

export const specificErrorActions: Set<string> = new Set([
  toastMiddlewareActions.userLocked.type,
  toastMiddlewareActions.sessionExpired.type,
  resetPasswordActions.invalidToken.type,
]);
