import React, { useState, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ProductImageStyled } from './CatalogExplorerTable.styled';
import { DialogModal } from 'src/components-dummy';

interface ImagePopupProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
}

export const ImagePopup = ({ src, alt, width = 64, height = 64 }: ImagePopupProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <ProductImageStyled
        src={src}
        alt={alt}
        width={width}
        height={height}
        style={{ cursor: 'pointer' }}
        onClick={handleOpen}
      />
      <DialogModal
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'transparent',
            boxShadow: 'none',
            '&.MuiPaper-root': {
              borderRadius: '4px',
            },
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: 'white',
            boxShadow: 24,
            padding: 1,
            display: 'inline-block',
            borderRadius: '4px',
            height: '100%',
            maxHeight: '90vh',
            maxWidth: '90vw',
            overflow: 'auto',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8, color: 'text.primary' }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={src}
            alt={alt}
            style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
      </DialogModal>
    </Box>
  );
};
