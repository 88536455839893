import React from 'react';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  BackIconStyled,
  PageMainTitleRowStyled,
  PageMainTitleStyled,
  PageSubTitleRowStyled,
  PageTitleWrapperStyled,
  PrimaryCatalogStyled,
  SeparatorBulletStyled,
  TitleContextStyled,
} from './CatalogExplorerPageHeader.styles';
import { IShopCatalog } from 'src/services';
import { TitleMainStyled } from '../../CatalogManager.styles';
import { FlagIcon } from 'src/components-bl/Lexicon/components/LexiconPageHeader/LexiconPageHeader.styles';
import { MetaLanguage } from 'src/app-state-types';
import { addSeparatorsToNumber } from '../CatalogExplorer.helpers';

interface CatalogExplorerPageHeaderProps {
  catalog?: IShopCatalog;
  totalCatalogProducts?: number;
  navigateToCatalogsList: () => void;
  catalogName: string;
  languageMetadata?: MetaLanguage;
}

export const CatalogExplorerPageHeader = ({
  catalog,
  totalCatalogProducts,
  navigateToCatalogsList,
  catalogName,
  languageMetadata,
}: CatalogExplorerPageHeaderProps) => {
  const contextPreTitle = 'Catalog Manager > ';
  const mainTitleText = `View ${catalogName}`;
  const fullTitleText = contextPreTitle + mainTitleText;

  return (
    <PageTitleWrapperStyled>
      <PageMainTitleRowStyled>
        <PageMainTitleStyled>
          <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={navigateToCatalogsList} />
          <EllipsisWithTooltip tooltipText={fullTitleText} key={Math.random()}>
            <TitleMainStyled
              type={TypographyType.Heading}
              variant={TypographyVariant.ExtraSmallBold}
              className='syte-page-title'
            >
              <TitleContextStyled>{contextPreTitle}</TitleContextStyled>
              {mainTitleText}
            </TitleMainStyled>
          </EllipsisWithTooltip>
          {catalog?.isPrimary && (
            <PrimaryCatalogStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                Primary catalog
              </Typography>
            </PrimaryCatalogStyled>
          )}
        </PageMainTitleStyled>
      </PageMainTitleRowStyled>
      <PageSubTitleRowStyled>
        {totalCatalogProducts && (
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            {addSeparatorsToNumber(totalCatalogProducts)} Items
          </Typography>
        )}

        <SeparatorBulletStyled />
        <>
          {languageMetadata?.iconName && (
            <FlagIcon name={languageMetadata.iconName as AvailableIcons} />
          )}
          {catalog?.defaultLocale && (
            <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
              {languageMetadata?.displayName || ''} (Locale: {catalog?.defaultLocale || ''})
            </Typography>
          )}
        </>
      </PageSubTitleRowStyled>
    </PageTitleWrapperStyled>
  );
};
