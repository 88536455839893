import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ConfirmPassword } from '../ConfirmPassword';
import { PasswordInputProps } from './types';

export const PasswordInput = ({
  state,
  errors,
  setPartialState,
  canChangePassword,
}: PasswordInputProps): JSX.Element | null => {
  return canChangePassword ? (
    <>
      <div
        className='syte-user-form-change-password'
        onClick={_ => setPartialState({ changePasswordEnabled: !state.changePasswordEnabled })}
      >
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Change Password
        </Typography>
      </div>
      {state.changePasswordEnabled && (
        <ConfirmPassword
          requireOldPassword
          state={{
            oldPassword: state.oldPassword,
            newPassword: state.newPassword,
            confirmNewPassword: state.confirmNewPassword,
          }}
          onChange={setPartialState}
          errors={{
            oldPassword: errors.oldPassword,
            newPassword: errors.newPassword,
            confirmNewPassword: errors.confirmNewPassword,
          }}
        />
      )}
    </>
  ) : null;
};
