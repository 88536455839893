/* eslint-disable no-param-reassign */
import { Experiment, ExperimentSlim, ExperimentStatus } from 'src/services';
import {
  ExperimentButtonsAction,
  ExperimentButtonsActionTypes,
} from 'src/components-bl/Experiment/ExperimentActionButtons/Actions';
import {
  ExperimentCardAction,
  ExperimentListAction,
  ExperimentListActionTypes,
  ExperimentFormAction,
  ExperimentFormActionTypes,
  CreateExperimentFormActionTypes,
  CreateExperimentFormAction,
  VariantsTableActionTypes,
  VariantsTableAction,
  ExperimentCardActionTypes,
  RankingStrategyActionTypes,
  RankingStrategyAction,
  PersonalizationActionTypes,
  PersonalizationSettingsAction,
  RecEnginesActionTypes,
  RecEnginesGeneralSettingsAction,
  ShopFeatureTogglesFormAction,
  LogHistoryPanelActionTypes,
  LogHistoryPanelAction,
  VariantPanelActionTypes,
  VariantPanelAction,
  ShopTheLookActionTypes,
  ShopTheLookSettingsAction,
  BoughtTogetherActionTypes,
  BoughtTogetherSettingsAction,
  RecentlyViewedActionTypes,
  RecentlyViewedSettingsAction,
  SimilarItemsActionTypes,
  SimilarItemsSettingsAction,
  DiscoveryBannerActionTypes,
  DiscoveryBannerSettingsAction,
  EnableUiTestActionTypes,
  EnableUiTestAction,
} from 'src/components-bl';

import {
  SideNavContainerAction,
  SideNavContainerActionTypes,
  EditExperimentContainerActionTypes,
  EditExperimentContainerAction,
  appHeaderActions,
} from 'src/containers';
import { createReducer } from '@reduxjs/toolkit';
import { rootContainerActions } from 'src/root-container/Actions';
import { MerchandisingRulesListActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components/MerchandisingRulesList/Actions/MerchandisingRulesList.actions';
import { merchandisingRulesFormActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.actions';
import { AnyAction } from 'redux';
import { ExperimentsReducerState } from '../app-state-types';

const defaultState: ExperimentsReducerState = {
  selectedExperiment: undefined,
  selectedExperimentId: undefined,
  runningExperiment: undefined,
  experiments: undefined,
  selectedVariantId: undefined,
  experimentsEnabled: false,
  updatedVariant: false,
  variantLogPanelOpened: false,
  variantLogPanelFeature: undefined,
  selectedVariantFeatureId: undefined,
};

function updateExperimentStatus(
  updatedExperiment: ExperimentSlim | undefined,
  experiments: ExperimentSlim[] | undefined
) {
  return experiments?.map(experiment => {
    if (experiment.id === updatedExperiment?.id) {
      return updatedExperiment;
    }
    return experiment;
  });
}

function findRunningExperiment(
  experiments: ExperimentSlim[] | undefined
): ExperimentSlim | undefined {
  if (!experiments) {
    return undefined;
  }
  return experiments.find(experiment => experiment.status === ExperimentStatus.Running);
}

// for toolkit actions use addCase or addMatcher
const experimentsReducerNonToolKit = (
  state = defaultState,
  action?:
    | ExperimentCardAction
    | ExperimentListAction
    | EditExperimentContainerAction
    | ExperimentFormAction
    | VariantsTableAction
    | CreateExperimentFormAction
    | ExperimentCardAction
    | ExperimentButtonsAction
    | ExperimentListAction
    | SideNavContainerAction
    | RankingStrategyAction
    | ShopFeatureTogglesFormAction
    | LogHistoryPanelAction
    | VariantPanelAction
    | ShopTheLookSettingsAction
    | BoughtTogetherSettingsAction
    | RecentlyViewedSettingsAction
    | PersonalizationSettingsAction
    | RecEnginesGeneralSettingsAction
    | SimilarItemsSettingsAction
    | EnableUiTestAction
    | DiscoveryBannerSettingsAction
    | EnableUiTestAction
    | AnyAction
): ExperimentsReducerState => {
  switch (action?.type) {
    case ExperimentListActionTypes.GetExperimentsSuccess:
      return {
        ...state,
        experiments: action.payload.experiments,
        runningExperiment: findRunningExperiment(action.payload.experiments),
      };
    case ExperimentFormActionTypes.UpdateExperimentSuccess:
      return {
        ...state,
        selectedExperiment: action.payload.experiment,
      };
    case ExperimentButtonsActionTypes.RunExperimentSuccess:
      return {
        ...state,
        experiments: updateExperimentStatus(action.payload.experiment, state.experiments),
        runningExperiment: action.payload,
        selectedExperiment: action.payload.experiment as Experiment,
      };

    case ExperimentButtonsActionTypes.StopExperimentSuccess:
      return {
        ...state,
        experiments: updateExperimentStatus(action.payload.experiment, state.experiments),
        runningExperiment: undefined,
        selectedExperiment: action.payload.experiment as Experiment,
      };
    case EditExperimentContainerActionTypes.GetExperimentSuccess:
      return {
        ...state,
        selectedExperiment: action.payload.experiment,
        updatedVariant: !(state.selectedExperiment && state.selectedVariantId),
      };
    case EditExperimentContainerActionTypes.ClearCurrentExperiment:
      return {
        ...state,
        selectedExperiment: defaultState.selectedExperiment,
        selectedExperimentId: defaultState.selectedExperimentId,
        selectedVariantId: defaultState.selectedVariantId,
      };
    case CreateExperimentFormActionTypes.CreateExperimentSuccess: {
      return {
        ...state,
        experiments: [action.payload.experiment, ...(state.experiments || [])],
        selectedExperiment: defaultState.selectedExperiment,
        selectedExperimentId: defaultState.selectedExperimentId,
        selectedVariantId: defaultState.selectedVariantId,
      };
    }
    case VariantsTableActionTypes.SelectVariant: {
      return { ...state, selectedVariantId: action.payload.variantId };
    }

    case EditExperimentContainerActionTypes.CloseVariantPanel: {
      return { ...state, selectedVariantId: undefined, selectedVariantFeatureId: undefined };
    }

    case ExperimentCardActionTypes.DeleteExperimentSuccess:
      return {
        ...state,
        experiments: action.payload.experiments,
      };

    case SideNavContainerActionTypes.GetExperimentSettingsSuccess:
      return {
        ...state,
      };

    case RankingStrategyActionTypes.UpdateRankingWeightsSuccess:
    case ShopTheLookActionTypes.UpdateShopTheLookSettingsSuccess:
    case BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsSuccess:
    case RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsSuccess:
    case PersonalizationActionTypes.UpdatePersonalizationSettingsSuccess:
    case RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsSuccess:
    case SimilarItemsActionTypes.UpdateSimilarItemsSettingsSuccess:
    case EnableUiTestActionTypes.UpdateEnableUiTestSuccess:
    case DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess:
    case MerchandisingRulesListActions.updateRule.fulfilled.type:
    case MerchandisingRulesListActions.createRule.fulfilled.type:
    case MerchandisingRulesListActions.deleteRule.fulfilled.type:
    case merchandisingRulesFormActions.createMerchandisingRule.fulfilled.type:
    case merchandisingRulesFormActions.updateMerchandisingRule.fulfilled.type:
      if (state.selectedExperiment && state.selectedVariantId) {
        return {
          ...state,
          updatedVariant: true,
        };
      }
      return state;
    case VariantPanelActionTypes.ShowChangeLog:
      return {
        ...state,
        variantLogPanelOpened: action.payload.opened,
        variantLogPanelFeature: action.payload.feature,
      };
    case LogHistoryPanelActionTypes.SetOpened:
      return {
        ...state,
        variantLogPanelOpened: false,
        variantLogPanelFeature: undefined,
      };
    case VariantPanelActionTypes.SetSelectedVariantFeature:
      return { ...state, selectedVariantFeatureId: action.payload.selectedVariantFeatureId };
    default:
      return state;
  }
};

export const experimentsReducer = createReducer(defaultState, builder => {
  // for actions made by redux toolkit use:
  // builder.addCase() or match()
  builder
    .addCase(appHeaderActions.experimentUpdated.fulfilled, (state, action) => {
      state.experiments = action.payload.experiments;

      const updatedExperiment = action.payload.experiments.find(
        experiment => experiment.id === action.payload.experimentId
      );

      switch (updatedExperiment.status) {
        case ExperimentStatus.Running: {
          state.runningExperiment = updatedExperiment;
          break;
        }
        case ExperimentStatus.Ended: {
          if (state.runningExperiment?.id === action.payload.experimentId) {
            state.runningExperiment = undefined;
          }
          break;
        }
        default:
          break;
      }
    })
    .addCase(appHeaderActions.experimentDeleted, (state, action) => {
      const existingExperiment = state.experiments?.find(
        experiment => experiment.id === action.payload.experimentId
      );

      if (existingExperiment) {
        state.experiments = state.experiments?.filter(experiment => {
          return experiment.id !== action.payload.experimentId;
        });
      }

      if (state.selectedExperiment?.id === action.payload.experimentId) {
        state.selectedExperiment = undefined;
      }

      if (state.selectedExperimentId === action.payload.experimentId) {
        state.selectedExperimentId = undefined;
      }
    })
    .addCase(appHeaderActions.experimentCreated.fulfilled, (state, action) => {
      state.experiments = action.payload.experiments;
    })
    .addCase(rootContainerActions.checkExperimentRunning.fulfilled, (state, action) => {
      return {
        ...state,
        runningExperiment: action.payload.runningExperiment,
      };
    })
    .addCase(rootContainerActions.resetRunningExperiment, state => {
      return {
        ...state,
        runningExperiment: undefined,
      };
    });
  builder.addDefaultCase(experimentsReducerNonToolKit);
});
