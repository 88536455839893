import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationType, Page } from 'src/components-dummy';
import {
  ICatalogProduct,
  IShopCatalog,
} from 'src/services/src/service/types/catalog-manager/catalog-manager';
import {} from 'src/components-bl/CatalogManager/CatalogManager.styles';
import { Dispatch } from 'src/components-bl/types';
import { PageStyled } from './CatalogExplorerPage.styles';
import { CatalogExplorerPageActions } from './CatalogExplorerPage.actions';
import { CatalogExplorerProductsView } from '../CatalogExplorerProductsView/CatalogExplorerProductsView';
import { ShopDataField } from 'src/services';
import { useManageDataFieldsForProduct } from './useManageDataFieldsForProduct';
import {
  DEFAULT_RESULTS_PER_PAGE_ENUM,
  OnPaginationChange,
  PaginationState,
} from 'src/components-dummy/PaginationToolBar';
import { CatalogExplorerPageHeader } from '../CatalogExplorerPageHeader/CatalogExplorerPageHeader';
import { MetaLanguage } from 'src/app-state-types';
import { isAbortError } from 'src/utils/error-helpers';
import { usePreviousState } from 'src/hooks';
import { debounce } from 'lodash';

export interface CatalogExplorerPageProps {
  catalog?: IShopCatalog;
  catalogProducts?: ICatalogProduct[];
  totalCatalogProducts?: number;
  shopDataFields?: ShopDataField[];
  navigateToCatalogsList: () => void;
  catalogName: string;
  shopId: number;
  dispatch: Dispatch;
  languageMetadata?: MetaLanguage;
}

export const CatalogExplorerPage = ({
  catalog,
  catalogProducts,
  totalCatalogProducts,
  shopDataFields,
  navigateToCatalogsList,
  catalogName,
  shopId,
  dispatch,
  languageMetadata,
}: CatalogExplorerPageProps): JSX.Element => {
  const getProductsCancellationRef = useRef(null as AbortController | null);

  const [isPendingOnFetchProducts, setIsPendingOnFetchProducts] = useState(false);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    skip: 0,
    totalItems: catalogProducts?.length || 0,
    limit: DEFAULT_RESULTS_PER_PAGE_ENUM[10],
  });

  const { productDataFieldsToFetch, dataFieldsMapByName } = useManageDataFieldsForProduct({
    shopDataFields,
    catalogName,
  });

  const prevProductDataFieldsToFetch = usePreviousState(productDataFieldsToFetch);

  const cancelGetProductsSignal = () => {
    if (!getProductsCancellationRef.current) {
      return;
    }

    if (getProductsCancellationRef.current.signal.aborted) {
      return;
    }

    try {
      getProductsCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      getProductsCancellationRef.current = null;
    }
  };

  const getProducts = useCallback(async () => {
    if (!productDataFieldsToFetch?.length) {
      return;
    }

    // Multi locale currently not supported
    if (catalog?.isMultiLocale) {
      navigateToCatalogsList();
      return;
    }

    setIsPendingOnFetchProducts(true);

    cancelGetProductsSignal();

    getProductsCancellationRef.current = new AbortController();

    let aborted = false;

    const thunkPromise: any = dispatch(
      CatalogExplorerPageActions.getProducts({
        shopId,
        catalogName,
        dataFields: productDataFieldsToFetch,
        filters: { skip: paginationState.skip, limit: paginationState.limit },
        cancellationSignal: getProductsCancellationRef.current.signal,
      })
    ) as any;

    thunkPromise
      .unwrap()
      .catch((apiError: any) => {
        aborted = isAbortError(apiError.error);

        if (!aborted) {
          console.error(apiError);
          dispatch(
            CatalogExplorerPageActions.notification({
              type: NotificationType.Error,
              customMessage: 'Failed to fetch catalog products',
            })
          );
        }
      })
      .finally(() => {
        if (!aborted || prevProductDataFieldsToFetch !== productDataFieldsToFetch) {
          setIsPendingOnFetchProducts(false);
        }
      });
  }, [
    paginationState.skip,
    paginationState.limit,
    productDataFieldsToFetch,
    catalog?.isMultiLocale,
    shopId,
    catalogName,
  ]);

  const debouncedGetProducts = useCallback(debounce(getProducts, 300), [getProducts]);

  useEffect(() => {
    if (!shopId || !catalogName) {
      return;
    }

    debouncedGetProducts();

    return () => {
      debouncedGetProducts.cancel();
    };
  }, [catalogName, getProducts, shopId]);

  useEffect(() => {
    return () => {
      cancelGetProductsSignal();
      dispatch(CatalogExplorerPageActions.clearCatalogExplorerData());
    };
  }, []);

  const onPaginationChange: OnPaginationChange = useCallback(newState => {
    setPaginationState(prevState => {
      if (prevState.skip !== newState.skip || prevState.limit !== newState.limit) {
        setIsPendingOnFetchProducts(true);
      }
      return { ...prevState, ...newState };
    });
  }, []);

  return (
    <PageStyled>
      <Page.Header>
        <CatalogExplorerPageHeader
          catalogName={catalogName}
          catalog={catalog}
          totalCatalogProducts={totalCatalogProducts}
          navigateToCatalogsList={navigateToCatalogsList}
          languageMetadata={languageMetadata}
        />
      </Page.Header>
      <Page.Content>
        <CatalogExplorerProductsView
          catalogProducts={catalogProducts}
          totalCatalogProducts={totalCatalogProducts}
          shopId={shopId}
          isPendingOnFetchProducts={isPendingOnFetchProducts}
          dataFieldsMapByName={dataFieldsMapByName}
          paginationState={paginationState}
          locale={catalog?.defaultLocale}
          onPaginationChange={onPaginationChange}
        />
      </Page.Content>
    </PageStyled>
  );
};
