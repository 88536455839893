import { UserTypes, AuthTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { UsersMapper } from '../users/users-mapper';
import { Buffer } from 'buffer';

export class Auth extends ApiServiceBase {
  constructor() {
    super('auth');
  }

  async me(): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/me`;
    const response = await this.httpService.get({ url });
    return UsersMapper.mapBackendUser(response.data);
  }

  async login({ email, password }: AuthTypes.LoginArgs): Promise<UserTypes.User> {
    const payload = {
      email,
      password: Buffer.from(password, 'utf8').toString('base64'),
    };

    const url = `${this.serviceBaseUri}/login`;
    const response = await this.httpService.post({ url, data: payload });

    return UsersMapper.mapBackendUser(response.data);
  }

  async logout(): Promise<string> {
    const url = `${this.serviceBaseUri}/logout`;
    const response = await this.httpService.post({ url });
    return response.data;
  }
}
