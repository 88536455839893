import React, { useCallback } from 'react';
import { PaginationRowStyled, LimitDropDownStyled, GroupStyled } from './PaginationToolBar.styles';
import { PaginationState } from './PaginationToolBar.types';
import { PaginationItemsPerPage } from '../Pagination/PaginationItemsPerPage';
import { PaginationGoToPageByText } from '../Pagination/PaginationGoToPageByText';
import { Pagination } from '../Pagination/Pagination';

/**
 * Types & constants
 */
export const DEFAULT_RESULTS_PER_PAGE_ENUM = {
  10: 10,
  20: 20,
  30: 30,
  40: 40,
  50: 50,
  100: 100,
} as const;

const DEFAULT_RESULTS_PER_PAGE_OPTIONS: number[] = Object.values(DEFAULT_RESULTS_PER_PAGE_ENUM);

export type OnPaginationChange = (state: Pick<PaginationState, 'skip' | 'limit'>) => void;

export interface PaginationToolBarProps extends PaginationState {
  onChange: OnPaginationChange;
  className?: string;
  resultsPerPageOptions?: number[];
}

/**
 * Main
 */
export const PaginationToolBar = ({
  skip = 0,
  limit,
  totalItems,
  resultsPerPageOptions = DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  className = 'pagination-toolbar',
  onChange,
}: PaginationToolBarProps): JSX.Element => {
  const handleChange = useCallback(
    (partialState: Partial<PaginationState>) => {
      onChange({ skip, limit, ...partialState });
    },
    [skip, limit]
  );

  const onSkipChange = useCallback(
    (newSkipCount: number) => {
      handleChange({ skip: newSkipCount });
    },
    [handleChange]
  );

  const onLimitChange = useCallback(
    (newLimit: number) => {
      // Reset skip value when limit changes
      handleChange({ skip: 0, limit: newLimit });
    },
    [handleChange]
  );

  const onPageChangeByText = useCallback(
    args => {
      onSkipChange(args.skip);
    },
    [onSkipChange]
  );

  return (
    <PaginationRowStyled className={className}>
      <LimitDropDownStyled>
        <PaginationItemsPerPage
          value={limit}
          optionsPerPage={resultsPerPageOptions}
          text='Items per page'
          onChange={onLimitChange}
        />
      </LimitDropDownStyled>

      <GroupStyled>
        <PaginationGoToPageByText
          totalItems={totalItems}
          itemsPerPage={limit}
          onPageChange={onPageChangeByText}
        />
        <Pagination
          skip={skip}
          itemsPerPage={limit}
          pagesToShow={3}
          totalItemsCount={totalItems}
          onPageChange={onSkipChange}
        />
      </GroupStyled>
    </PaginationRowStyled>
  );
};
