import React, { useMemo } from 'react';
import { Password, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ConfirmPasswordProps } from './types';
import { validationIndications } from './constants';
import { ConfirmPasswordStyled, PasswordStyled, TypographyStyled } from './ConfirmPassword.styles';

export const ConfirmPassword = ({
  state,
  onChange,
  errors,
  requireOldPassword,
}: ConfirmPasswordProps): JSX.Element => {
  const confirmPasswordErrorMessage =
    !!state.newPassword?.length &&
    state.confirmNewPassword !== state.newPassword &&
    "Passwords don't match";

  const validationIndicationsTypography = useMemo(() => {
    return validationIndications.map(({ types, message }) => {
      const isError = [errors?.newPassword?.type, errors?.confirmNewPassword?.type].some(type =>
        types.includes(type)
      );

      return (
        <TypographyStyled
          key={message}
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumRegular}
          isError={isError}
        >
          {message}
        </TypographyStyled>
      );
    });
  }, [errors]);

  return (
    <ConfirmPasswordStyled>
      {requireOldPassword && (
        <PasswordStyled
          className='syte-confirm-password-old-password'
          value={state.oldPassword || ''}
          onChange={oldPassword => onChange({ oldPassword })}
          error={errors?.oldPassword?.message}
          placeholder='Current password'
          autoComplete='off'
        />
      )}
      {validationIndicationsTypography}
      <Password
        className='syte-confirm-password-new-password'
        value={state.newPassword || ''}
        onChange={newPassword => onChange({ newPassword })}
        error={!!errors?.newPassword?.message}
        placeholder='New password'
      />
      <Password
        className='syte-confirm-password-confirm-password'
        value={state.confirmNewPassword || ''}
        onChange={confirmNewPassword => onChange({ confirmNewPassword })}
        error={confirmPasswordErrorMessage}
        placeholder='Confirm new password'
      />
    </ConfirmPasswordStyled>
  );
};
