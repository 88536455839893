import { orderBy } from 'lodash';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';

export function getOldestNonExpiredApiKey(apiKeys: IApiKey[]): IApiKey | undefined {
  const now = new Date();
  const nonExpiredKeys = apiKeys.filter(apiKey => {
    if (!apiKey.expiration) return true;

    return now < apiKey.expiration;
  });

  if (nonExpiredKeys.length === 0) return undefined;

  const orderedKeys = orderBy(nonExpiredKeys, ['createdAt']);

  return orderedKeys[0];
}
