import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { CatalogManagerMapper } from './catalog-manager.mapper';
import {
  IShopCatalog,
  IShopCatalogAPI,
  ICatalogPlanCreate,
  ICatalogPlanAPI,
  DownloaderTypesEnum,
  ICatalogFeedUploadLog,
  ICatalogUploadErrorsPaginated,
  CatalogUploadErrorsPaginationWithFilters,
  ICatalogExplorerProductsFilters,
  ICatalogProduct,
  CatalogExplorerProductsGetResponse,
} from '../types';
import { downloadResponseFile } from '../../common/utils';

const buildFormDataForCreateAndUpdate = ({
  verticals,
  genders,
  name,
  productsFile,
  sftpServerSettings,
  wgetServerSettings,
  downloaderType,
  scheduling,
}: ICatalogPlanCreate): FormData => {
  const formData = new FormData();

  if (productsFile) {
    formData.append('file', productsFile);
  }

  formData.append('verticals', JSON.stringify(verticals));
  formData.append('genders', JSON.stringify(genders));

  if (downloaderType === DownloaderTypesEnum.SFTP || downloaderType === DownloaderTypesEnum.FTP) {
    formData.append('sftpServerSettings', JSON.stringify(sftpServerSettings));
  }

  if (downloaderType === DownloaderTypesEnum.WGET) {
    formData.append('wgetServerSettings', JSON.stringify(wgetServerSettings));
  }

  if (scheduling) {
    formData.append('scheduling', JSON.stringify(scheduling));
  }

  formData.append('name', name);
  formData.append('downloaderType', downloaderType);

  return formData;
};

export class CatalogManagerService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/catalogs`;
  }

  async getCatalogs(shopId: number): Promise<IShopCatalog[]> {
    const url = this.getUrl(shopId);
    const response: AxiosResponse<{ catalogs: IShopCatalogAPI[] }> = await this.httpService.get({
      url,
    });
    return CatalogManagerMapper.mapCatalogs(response);
  }

  async getCatalogById({ shopId, id }: { shopId: number; id: string }): Promise<IShopCatalog> {
    const url = `${this.getUrl(shopId)}/${id}`;
    const response: AxiosResponse<IShopCatalogAPI> = await this.httpService.get({
      url,
    });
    return CatalogManagerMapper.mapCatalog(response.data);
  }

  async getCatalogPlan({
    shopId,
    catalogName,
  }: {
    shopId: number;
    catalogName: string;
  }): Promise<ICatalogPlanAPI> {
    const url = `${this.getUrl(shopId)}/get-by-name/${catalogName}`;
    const response: AxiosResponse<{ data: ICatalogPlanAPI }> = await this.httpService.get({
      url,
    });

    return CatalogManagerMapper.mapSingleCatalog(response);
  }

  async getCatalogExplorerProducts({
    shopId,
    catalogName,
    dataFields,
    filters,
    cancellationSignal,
  }: {
    shopId: number;
    catalogName: string;
    dataFields: string[];
    filters: ICatalogExplorerProductsFilters;
    cancellationSignal?: AbortSignal;
  }): Promise<CatalogExplorerProductsGetResponse> {
    const params = {
      limit: filters.limit,
      skip: filters.skip,
      dataFields: dataFields.join(','),
    };

    const url = `${this.getUrl(shopId)}/get-by-name/${catalogName}/products`;

    const { data }: AxiosResponse<{ data: ICatalogProduct[] }> = await this.httpService.get({
      url,
      requestConfig: { params, signal: cancellationSignal },
    });

    return data as unknown as CatalogExplorerProductsGetResponse;
  }

  async getCatalogFeedUploadLog({
    shopId,
    catalogName,
  }: {
    shopId: number;
    catalogName: string;
  }): Promise<ICatalogFeedUploadLog[]> {
    const url = `${this.getUrl(shopId)}/get-by-name/${catalogName}/upload-logs`;
    const response: AxiosResponse<{ data: ICatalogPlanAPI }> = await this.httpService.get({
      url,
    });

    const mappedData = CatalogManagerMapper.mapCatalogFeedUploadLogs(response);

    return mappedData;
  }

  async getCatalogUploadErrorReports({
    shopId,
    catalogName,
    jobId,
    ...paginationWithFilters
  }: {
    shopId: number;
    catalogName: string;
    jobId: string;
  } & CatalogUploadErrorsPaginationWithFilters): Promise<ICatalogUploadErrorsPaginated> {
    const url = `${this.getUrl(shopId)}/get-by-name/${catalogName}/upload-logs/${jobId}`;

    const params = {
      ...paginationWithFilters,
      categories: paginationWithFilters.categories?.join(','),
      syteCategories: paginationWithFilters.syteCategories?.join(','),
      actions: paginationWithFilters.actions?.join(','),
      errorDescriptions: paginationWithFilters.errorDescriptions?.join(','),
    };

    const response: AxiosResponse<{ data: ICatalogPlanAPI }> = await this.httpService.get({
      url,
      requestConfig: { params },
    });

    const mappedData = CatalogManagerMapper.mapCatalogUploadErrorReport(response);

    return mappedData;
  }

  async downloadUploadLogFeedFile({
    shopId,
    catalogName,
    jobId,
  }: {
    shopId: number;
    catalogName: string;
    jobId: string;
  }): Promise<void> {
    const url = `${this.getUrl(shopId)}/get-by-name/${catalogName}/upload-logs/${jobId}/download`;

    const response: AxiosResponse<Blob | undefined> = await this.httpService.post({
      url,
      requestConfig: {
        responseType: 'blob',
      },
    });

    downloadResponseFile({
      response,
      defaultName: `${catalogName}_${jobId}.csv`,
    });
  }

  /**
   * Creates catalog with file
   */
  async createCatalog({
    shopId,
    data,
    cancellationSignal,
  }: {
    shopId: number;
    data: ICatalogPlanCreate;
    cancellationSignal?: AbortSignal;
  }): Promise<ICatalogPlanAPI> {
    const formData = buildFormDataForCreateAndUpdate(data);

    const url = `${this.getUrl(shopId)}`;

    const response = await this.httpService.post({
      url,
      data: formData,
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: cancellationSignal,
      },
    });

    return CatalogManagerMapper.mapSingleCatalog(response);
  }

  /**
   * Update catalog optionally with a file
   */
  async updateCatalogPlanConfig({
    shopId,
    data,
    cancellationSignal,
  }: {
    shopId: number;
    data: ICatalogPlanCreate;
    cancellationSignal?: AbortSignal;
  }): Promise<ICatalogPlanAPI> {
    const { name } = data;

    const url = `${this.getUrl(shopId)}/${name}`;

    const formData = buildFormDataForCreateAndUpdate(data);

    const response = await this.httpService.put({
      url,
      data: formData,
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: cancellationSignal,
      },
    });

    return CatalogManagerMapper.mapSingleCatalog(response);
  }

  async updateCatalog({
    shopId,
    id,
    ...payload
  }: Partial<Pick<IShopCatalog, 'isPrimary' | 'verticals' | 'isAugmentedSearchCatalog'>> & {
    shopId: number;
    id: string;
  }): Promise<IShopCatalog> {
    const url = `${this.getUrl(shopId)}/${id}`;

    const response: AxiosResponse<IShopCatalogAPI> = await this.httpService.patch({
      url,
      data: payload,
    });
    return CatalogManagerMapper.mapCatalog(response.data);
  }

  async deleteCatalog({ shopId, id }: { shopId: number; id: string }): Promise<void> {
    const url = `${this.getUrl(shopId)}/${id}`;

    await this.httpService.delete({
      url,
    });
  }

  async connectCatalog({
    shopId,
    name,
    isPrimary,
    isAugmentedSearchCatalog,
    verticals,
  }: Pick<
    IShopCatalog,
    'isPrimary' | 'name' | 'verticals' | 'shopId' | 'isAugmentedSearchCatalog'
  >): Promise<IShopCatalog> {
    const url = `${this.getUrl(shopId)}/connect`;

    const payload = {
      name,
      isPrimary,
      verticals,
      isAugmentedSearchCatalog,
    };

    const { data: catalog }: AxiosResponse<IShopCatalogAPI> = await this.httpService.post({
      url,
      data: payload,
    });
    return CatalogManagerMapper.mapCatalog(catalog);
  }

  async getAvailableLocales({
    shopId,
  }: {
    shopId: number;
  }): Promise<{ regions: string[]; languages: string[] }> {
    const url = `${this.getUrl(shopId)}/locales`;

    const { data }: AxiosResponse<{ regions: string[]; languages: string[] }> =
      await this.httpService.get({
        url,
      });

    return data;
  }

  async getTextSimilarity({
    shopId,
  }: {
    shopId: number;
  }): Promise<{ isTextSimilarityAvailable: boolean }> {
    const url = `${this.getUrl(shopId)}/text-similarity`;

    const { data }: AxiosResponse<{ isTextSimilarityAvailable: boolean }> =
      await this.httpService.get({
        url,
      });

    return data;
  }
}

export const catalogManagerService = new CatalogManagerService();
