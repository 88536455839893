import React from 'react';
import { ColorVariant } from '../../../../../services';
import { useDisplayVariants } from './helpers/use-display-variants';
import {
  ColorVariantItem,
  VariantsContainer,
  DisplayVariantsToggle,
  ColorVariantContainer,
} from './ProductTile.styles';

export const ColorVariants = ({
  variants,
  selectedIndex,
  onVariantClick,
}: {
  variants: ColorVariant[];
  selectedIndex: number;
  onVariantClick: (index: number) => void;
}): JSX.Element | null => {
  const colorVariants = variants?.map(variant => variant?.hexColor || []).flat();

  const { toggleRef, isExpanded, onToggle } = useDisplayVariants();

  if (!colorVariants?.length) return null;

  return (
    <VariantsContainer isExpanded={isExpanded}>
      {colorVariants.map((colorVariant, index) => {
        if (!colorVariant) {
          return null;
        }
        return (
          <ColorVariantContainer
            key={colorVariant}
            isSelected={index === selectedIndex}
            onClick={() => onVariantClick(index)}
          >
            <ColorVariantItem color={colorVariant} />
          </ColorVariantContainer>
        );
      })}
      <DisplayVariantsToggle ref={toggleRef} isExpanded={isExpanded} onClick={onToggle}>
        {isExpanded && 'Show Less'}
      </DisplayVariantsToggle>
    </VariantsContainer>
  );
};
