import Joi from 'joi';
import { isNumber } from 'lodash';
import { UserTypes } from 'src/services';
import { UserFormState } from './types';
import { emailValidationSchema, passwordV1, passwordV2 } from '../constants';

const rolesTranslation: Record<UserTypes.UserRoles, string> = {
  [UserTypes.UserRoles.ClientAdmin]: 'Client',
  [UserTypes.UserRoles.SyteAdmin]: 'Admin',
};

const userRolesNumValues = Object.values(UserTypes.UserRoles).filter(value =>
  isNumber(value)
) as UserTypes.UserRoles[];

export const userRolesDropdownOptionsSyteAdmin = userRolesNumValues.map(role => {
  return {
    value: role,
    text: rolesTranslation[role],
  };
});

export const userRolesDropdownOptionsClientAdmin = userRolesDropdownOptionsSyteAdmin.filter(
  item => item.value >= UserTypes.UserRoles.ClientAdmin
);

export const initialFormState: UserFormState = {
  email: '',
  firstName: undefined,
  lastName: undefined,
  changePasswordEnabled: false,
  oldPassword: undefined,
  newPassword: undefined,
  confirmNewPassword: undefined,
  role: undefined,
};

export const formValidationSchema = {
  email: emailValidationSchema,
  firstName: Joi.string().trim().min(1).max(50).optional().messages({
    'string.empty': 'First name can not be empty',
    'string.min': 'First name is too short',
    'string.max': 'First name is too long',
  }),
  lastName: Joi.string().trim().min(1).max(50).optional().messages({
    'string.empty': 'Last name can not be empty',
    'string.min': 'Last name is too short',
    'string.max': 'Last name is too long',
  }),
  changePasswordEnabled: Joi.boolean().optional(),
  oldPassword: Joi.any()
    .when('changePasswordEnabled', {
      is: true,
      then: passwordV1.schema,
      otherwise: passwordV1.schema.optional(),
    })
    .required(),
  newPassword: Joi.any()
    .when('changePasswordEnabled', {
      is: true,
      then: passwordV2.schema,
      otherwise: passwordV2.schema.optional(),
    })
    .required(),
  confirmNewPassword: Joi.any().when('changePasswordEnabled', {
    is: true,
    then: passwordV2.schema
      .valid(Joi.ref('newPassword'))
      .required()
      .messages({ 'any.only': 'Passwords do not match' }),
    otherwise: passwordV2.schema.optional(),
  }),
  role: Joi.number()
    .required()
    .valid(...userRolesNumValues),
};
