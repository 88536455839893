import React from 'react';
import { ColorVariant } from '../../../../../services';
import { useDisplayVariants } from './helpers/use-display-variants';
import { Typography, TypographyType, TypographyVariant } from '../../../../../components-dummy';
import { SizeVariantItem, VariantsContainer, DisplayVariantsToggle } from './ProductTile.styles';

export const SizeVariants = ({
  variants,
  selectedColorIndex,
  selectedSizeIndex,
  onVariantClick,
}: {
  variants: ColorVariant[];
  selectedColorIndex: number;
  selectedSizeIndex: number;
  onVariantClick: (index: number) => void;
}): JSX.Element | null => {
  const sizeVariants = variants?.[selectedColorIndex]?.size_variants;

  const { toggleRef, isExpanded, onToggle } = useDisplayVariants();

  if (!sizeVariants?.length) return null;

  return (
    <VariantsContainer isExpanded={isExpanded}>
      {sizeVariants.map((sizeVariant, index) => {
        if (!sizeVariant?.size) {
          return null;
        }
        return (
          <SizeVariantItem
            key={sizeVariant.id}
            isSelected={index === selectedSizeIndex}
            onClick={() => onVariantClick(index)}
          >
            <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
              {sizeVariant.size.toString()}
            </Typography>
          </SizeVariantItem>
        );
      })}
      <DisplayVariantsToggle ref={toggleRef} isExpanded={isExpanded} onClick={onToggle}>
        {isExpanded && 'Show Less'}
      </DisplayVariantsToggle>
    </VariantsContainer>
  );
};
