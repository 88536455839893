import { MANDATORY_DATA_FIELDS_MAP } from '../categoryExplorer.config';
import { TableHeadRow } from './CatalogExplorerTable.types';

export const MANDATORY_COLUMNS: Record<string, TableHeadRow> = {
  index: {
    header: '',
    minWidth: 44,
    width: 64,
    accessor: 'index',
  },
  [MANDATORY_DATA_FIELDS_MAP.imageUrl.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.imageUrl.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.imageUrl.value,
    width: 80,
    minWidth: 64,
  },
  [MANDATORY_DATA_FIELDS_MAP.title.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.title.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.title.value,
    width: 190,
  },
  [MANDATORY_DATA_FIELDS_MAP.parent_sku.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.parent_sku.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.parent_sku.value,
    width: 120,
  },
  [MANDATORY_DATA_FIELDS_MAP.color_sku.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.color_sku.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.color_sku.value,
    width: 165,
  },
  [MANDATORY_DATA_FIELDS_MAP.sku.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.sku.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.sku.value,
    width: 165,
  },
  [MANDATORY_DATA_FIELDS_MAP.price.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.price.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.price.value,
    width: 80,
  },
  [MANDATORY_DATA_FIELDS_MAP.originalPrice.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.originalPrice.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.originalPrice.value,
    minWidth: 90,
    width: 110,
  },
  [MANDATORY_DATA_FIELDS_MAP.categories.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.categories.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.categories.value,
    width: 135,
  },
  [MANDATORY_DATA_FIELDS_MAP.brand.value]: {
    header: MANDATORY_DATA_FIELDS_MAP.brand.text,
    accessor: MANDATORY_DATA_FIELDS_MAP.brand.value,
    width: 165,
  },
};
