import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  max-height: 40px;
`;

export const CatalogNameCellStyled = styled(TableV2.BodyRowCell)<{
  isClickable: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;
