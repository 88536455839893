import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Users } from 'src/services';
import { CreateUserArgs, UpdateUserObject } from 'src/services/src/service/users/types';

export interface UserFormNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

export interface UserFormUpdateUserRequestActionPayload extends UpdateUserObject {
  accountId: number;
}

const userService = new Users();

const notifyIsDirtyActionName = 'UserForm/NotifyIsDirty';

export const userFormActions = {
  updateUser: createAsyncThunk(
    'UserForm/UpdateUser',
    async (
      { accountId, ...userData }: UserFormUpdateUserRequestActionPayload,
      { rejectWithValue }
    ) => {
      try {
        const updatedUser = await userService.updateUser({
          accountId,
          userData,
        });

        return { user: updatedUser };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createUser: createAsyncThunk(
    'UserForm/CreateUser',
    async (parameters: CreateUserArgs, { rejectWithValue }) => {
      try {
        const createdUser = await userService.createUser(parameters);
        return { user: createdUser };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  notifyIsDirty: createAction<UserFormNotifyIsDirtyActionPayload>(notifyIsDirtyActionName),
};
