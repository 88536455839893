import styled from '@emotion/styled';
import { PopUp } from 'src/components-dummy';

export const PopUpWithOffsetStyled = styled.div<{
  width: number;
  height: number;
  top: number;
  left: number;
}>`
  .syte-popup-trigger {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    position: absolute;
    z-index: 1;
  }
`;

export const PopUpStyled = styled(PopUp)<{ isAIDetected: boolean }>`
  ${({ theme, isAIDetected }) =>
    isAIDetected
      ? `&:before {
            background-color: ${theme.palette.custom['primary-light']} !important;
            box-shadow: unset !important;
          }`
      : ''}
`;
