import { useMemo } from 'react';
import { ShopDataField } from 'src/services';
import { uniq } from 'lodash';
import { MANDATORY_DATA_FIELDS_MAP } from '../categoryExplorer.config';

interface UseManageDataFieldsForProductResult {
  productDataFieldsToFetch: string[];
  dataFieldsMapByName: Record<string, ShopDataField>;
}

export const useManageDataFieldsForProduct = ({
  shopDataFields,
  catalogName,
}: {
  shopDataFields?: ShopDataField[];
  catalogName: string;
}): UseManageDataFieldsForProductResult => {
  const dataFieldsMapByName = useMemo(() => {
    if (!shopDataFields) return [];

    return shopDataFields.reduce((prev, next) => {
      const isSupportsCatalogName = next.catalogNames.includes(catalogName);
      const isCatalogField = next.fieldType === 'catalog_field';
      const isTagging = next.name === 'tagging';
      const isTags = next.name === 'tags';

      const shouldIgnore = !isSupportsCatalogName || !isCatalogField || isTagging || isTags;

      if (shouldIgnore) {
        return prev;
      }

      prev[next.name] = next;
      return prev;
    }, {});
  }, [shopDataFields]);

  const productDataFieldsToFetch = useMemo(() => {
    if (!shopDataFields) return [];

    return uniq([...Object.keys(MANDATORY_DATA_FIELDS_MAP), ...Object.keys(dataFieldsMapByName)]);
  }, [dataFieldsMapByName]);

  return { productDataFieldsToFetch, dataFieldsMapByName };
};
