import { useEffect } from 'react';
import { Dispatch } from 'src/components-bl';
import {
  CustomInspirationsImageDetectionEndedEvent,
  CustomInspirationsImageDetectionStartedEvent,
  galleriesWebSocketService,
} from 'src/services/src/service/galleries';
import { useGalleriesWSActions } from './useGalleriesWS.actions';

export const useGalleriesWS = ({
  dispatch,
  shopId,
  selectedGalleryId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
  selectedGalleryId: string | undefined;
}): void => {
  const onImageTagsDetectionStart = (payload: CustomInspirationsImageDetectionStartedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.imageTagsDetectionStarted(payload.data));
    }
  };

  const onImageTagsDetectionEnd = (payload: CustomInspirationsImageDetectionEndedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.imageTagsDetectionEnded(payload.data));

      if (payload.data.errorKey) {
        dispatch(
          useGalleriesWSActions.errorNotification({ error: { errorKey: payload.data.errorKey } })
        );
      } else if (payload.data.aiSuggestedTags.length === 0) {
        dispatch(
          useGalleriesWSActions.notification({
            customMessage:
              'The suggestions generation process has been completed, and no suggestions were found.',
          })
        );
      } else if (payload.data.aiSuggestedTags.length === 1) {
        dispatch(
          useGalleriesWSActions.notification({
            customMessage:
              'The suggestion generation process has been completed, and 1 suggestion was found.',
          })
        );
      } else if (payload.data.aiSuggestedTags.length > 1) {
        dispatch(
          useGalleriesWSActions.notification({
            customMessage: `The suggestion generation process has been completed, and ${payload.data.aiSuggestedTags.length} suggestions were found.`,
          })
        );
      }
    }
  };

  useEffect(() => {
    galleriesWebSocketService.subscribeToImageTagsDetectionStartedEvents(onImageTagsDetectionStart);
    galleriesWebSocketService.subscribeToImageTagsDetectionEndedEvents(onImageTagsDetectionEnd);

    return () => {
      galleriesWebSocketService.clearListeners();
    };
  }, [shopId, selectedGalleryId]);
};
