import React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import {
  ButtonsContainer,
  ContentStyled,
  DialogMUIStyled,
  FooterStyled,
  HeaderStyled,
} from './DialogModal.style';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { Typography } from '../Typography/Typography';
import { TypographyType, TypographyVariant } from '../Typography';

const Title = ({ children }: { children: React.ReactChild | React.ReactChild[] }): JSX.Element => (
  <EllipsisWithTooltip tooltipPosition='top center' tooltipText={children as string}>
    <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
      {children}
    </Typography>
  </EllipsisWithTooltip>
);

/**
 * Used Material UI props
 */
export interface DialogMUIProps {
  sx?: DialogProps['sx'];
  hideBackdrop?: boolean;
  fullScreen?: boolean;
  open: boolean;
  ref?: DialogProps['ref'];
  className?: string;
  PaperProps?: DialogProps['PaperProps'];
  onClose?: () => void;
}

/**
 * Custom Props (extends ModalMaterialProps)
 */
interface DialogModalProps extends DialogMUIProps {
  children: React.ReactElement;
}

export const DialogModal = React.forwardRef(
  ({ children, open, ...rest }: DialogModalProps, ref: any): JSX.Element => {
    return (
      <DialogMUIStyled open={open} ref={ref} {...rest}>
        {children}
      </DialogMUIStyled>
    );
  }
);

export const DialogModalHeader = HeaderStyled;
export const DialogModalTitle = Title;
export const DialogModalContent = ContentStyled;
export const DialogModalFooter = FooterStyled;
export const DialogModalButtonsContainer = ButtonsContainer;
