import React, { useState, useMemo, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useValidateSchema } from 'src/hooks';
import {
  TextBox,
  Button,
  Select,
  SelectType,
  MenuItem,
  SelectOnChangeEvent,
} from 'src/components-dummy';
import { UserTypes } from 'src/services';
import { createOptionalPortal } from '../helpers';
import { isDataDirty } from '../../utils';
import { PasswordInput } from './PasswordInput';
import { UserFormState, UserFormProps } from './types';
import { userFormActions, UserFormUpdateUserRequestActionPayload } from './Actions';
import {
  initialFormState,
  formValidationSchema,
  userRolesDropdownOptionsSyteAdmin,
  userRolesDropdownOptionsClientAdmin,
} from './constants';
import './UserForm.scss';

function getInitialFormState(user: UserTypes.User | undefined): UserFormState {
  return user
    ? {
        ...initialFormState,
        email: user.email,
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
      }
    : initialFormState;
}

export const UserForm = ({
  user,
  dispatch,
  accountId,
  editingUser,
  className,
  formSaveButtonRef: formHeaderElementRef,
  onSubmit,
}: UserFormProps): JSX.Element => {
  const isCreateMode = useMemo(() => !user, [user]);

  const initialState = useMemo(() => getInitialFormState(user), [user]);
  const [formState, setFormState] = useState(initialState);
  const [isInProgress, setIsInProgress] = useState(false);

  const { canChangePassword, canChangeRole, canEditData, roleDropdownOptions } = useMemo(() => {
    const isEditingSelf = editingUser.userId === user?.userId;
    const isSyteAdmin = editingUser.role === UserTypes.UserRoles.SyteAdmin;
    return {
      canChangePassword: editingUser.userId === user?.userId,
      canChangeRole: !isEditingSelf && (isSyteAdmin || !user),
      canEditData: isEditingSelf || isSyteAdmin || !user,
      roleDropdownOptions:
        isSyteAdmin || user
          ? userRolesDropdownOptionsSyteAdmin
          : userRolesDropdownOptionsClientAdmin,
    };
  }, [editingUser.userId, user]);

  const { errors, validate, isValid, resetValidationState } = useValidateSchema<UserFormState>({
    schema: formValidationSchema,
    validateOnStart: false,
  });

  const setPartialState = useCallback(
    (partialState: Partial<UserFormState>) => {
      let updatedState = { ...formState, ...partialState };

      if ('changePasswordEnabled' in partialState && !partialState.changePasswordEnabled) {
        updatedState = {
          ...updatedState,
          oldPassword: initialState.oldPassword,
          newPassword: initialState.newPassword,
          confirmNewPassword: initialState.confirmNewPassword,
        };
      }

      setFormState(updatedState);
      validate({ dataToValidate: updatedState });
    },
    [formState, validate, initialState]
  );

  const isDirty = isDataDirty(formState, initialState);

  const resetForm = useCallback(() => {
    setFormState(initialState);

    resetValidationState();
  }, [setFormState, resetValidationState, initialState]);

  const onSubmitForm = useCallback(async () => {
    if (!isValid) {
      return;
    }

    setIsInProgress(true);

    try {
      const isEmailAndRoleExits = formState.email && formState.role;

      if (isCreateMode && isEmailAndRoleExits) {
        await (
          dispatch(
            userFormActions.createUser({
              userData: {
                email: formState.email,
                accessLevel: formState.role,
              },
              accountId,
            })
          ) as any
        ).unwrap();
      } else if (isEmailAndRoleExits && user?.userId) {
        let updateUserObject = {
          email: formState.email,
          accountId,
          role: formState.role,
          userId: user.userId,
          firstName: formState.firstName,
          lastName: formState.lastName,
        } as Partial<UserFormUpdateUserRequestActionPayload>;

        if (canChangePassword && formState.newPassword && formState.oldPassword) {
          updateUserObject = {
            ...updateUserObject,
            newPassword: formState.newPassword,
            oldPassword: formState.oldPassword,
          };
        }

        await (
          dispatch(
            userFormActions.updateUser(updateUserObject as UserFormUpdateUserRequestActionPayload)
          ) as any
        ).unwrap();
      }
    } catch (error) {
      console.error(error);
    }

    setIsInProgress(false);

    onSubmit?.();
  }, [dispatch, user, formState, accountId, editingUser.userId, isCreateMode]);

  useEffect(() => {
    resetForm();
  }, [initialState]);

  useEffect(() => {
    dispatch(userFormActions.notifyIsDirty({ isDirty }));

    return () => {
      dispatch(userFormActions.notifyIsDirty({ isDirty: false }));
    };
  }, [isDirty]);

  const applyButton = (
    <Button
      onClick={onSubmitForm}
      variant='primary'
      disabled={!(isValid && isDirty)}
      className='apply-button'
      loading={isInProgress}
    >
      Save
    </Button>
  );

  const applyButtonSection = createOptionalPortal({
    portalContent: applyButton,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='syte-user-form-header'>{applyButton}</div>,
  });

  const handleRoleChange: SelectOnChangeEvent = event => {
    setPartialState({ role: event.target.value as unknown as UserTypes.UserRoles });
  };

  return (
    <div className={classNames('syte-user-form', className)}>
      {!isCreateMode && (
        <>
          <TextBox
            className='syte-user-form-first-name'
            value={formState.firstName}
            label='First name'
            placeholder='Type...'
            onChange={firstName => setPartialState({ firstName })}
            error={errors.firstName?.message}
            disabled={!canEditData}
          />
          <TextBox
            className='syte-user-form-last-name'
            value={formState.lastName}
            label='Last name'
            placeholder='Type...'
            onChange={lastName => setPartialState({ lastName })}
            error={errors.lastName?.message}
            disabled={!canEditData}
          />
        </>
      )}
      <TextBox
        className='syte-user-form-email'
        value={formState.email}
        label='Email'
        placeholder='Type...'
        onChange={email => setPartialState({ email })}
        error={!!errors.email}
        disabled={!canEditData}
      />
      <PasswordInput
        state={formState}
        errors={errors}
        setPartialState={setPartialState}
        canChangePassword={canChangePassword}
      />
      <Select
        type={SelectType.Primary}
        disabled={!canChangeRole}
        label='Role'
        value={formState.role}
        onChange={handleRoleChange}
        isError={!!errors.role}
        className='syte-drop-down syte-user-form-role'
      >
        {roleDropdownOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
      {applyButtonSection}
    </div>
  );
};
