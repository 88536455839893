import React, { useCallback, useState } from 'react';
import { Button } from 'src/components-dummy';
import { CustomInspirationsGalleryImage } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { ExpandableOptions, ResetDraftConfirmationDialog } from './components';
import { ImageSettingsStyled, SettingsFooterStyled } from './ImageSettings.styles';

interface ImageSettingsProps {
  image: CustomInspirationsGalleryImage;
  isDirty: boolean;
  failedToLoadImage: boolean;
  isValid: boolean;
  errors: ParseJoiValidateResponse<Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>>;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
  onSave: () => void;
  resetDraft: () => void;
  removeTagById: (id: string) => void;
}

export function ImageSettings({
  image,
  isDirty,
  failedToLoadImage,
  errors,
  isValid,
  onChange,
  onSave,
  resetDraft,
  removeTagById,
}: ImageSettingsProps): JSX.Element {
  const [showConfirmResetDraftDialog, setShowConfirmResetDraftDialog] = useState(false);

  const onCancel = useCallback(() => {
    setShowConfirmResetDraftDialog(true);
  }, [setShowConfirmResetDraftDialog]);

  const onResetConfirm = useCallback(() => {
    resetDraft();
    setShowConfirmResetDraftDialog(false);
  }, [resetDraft, setShowConfirmResetDraftDialog]);

  const onResetCancel = useCallback(() => {
    setShowConfirmResetDraftDialog(false);
  }, [setShowConfirmResetDraftDialog]);

  return (
    <>
      <ImageSettingsStyled>
        <ExpandableOptions
          image={image}
          disabled={failedToLoadImage}
          errors={errors}
          onChange={onChange}
          removeTagById={removeTagById}
        />
        <SettingsFooterStyled>
          <Button variant='tertiary' onClick={onCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onSave} disabled={!isDirty || !isValid}>
            Save
          </Button>
        </SettingsFooterStyled>
      </ImageSettingsStyled>
      {showConfirmResetDraftDialog && (
        <ResetDraftConfirmationDialog onConfirm={onResetConfirm} onCancel={onResetCancel} />
      )}
    </>
  );
}
