import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const TagsDetectionBackdropContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  min-height: 110px;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: auto;
`;

export const StartAnimated = styled.div`
  animation: icon-animation 3s linear infinite;

  will-change: transform;

  position: absolute;
  overflow: hidden;

  @keyframes icon-animation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    10% {
      transform: scale(0.5);
      opacity: 1;
    }
    40% {
      transform: scale(0.7);
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    70% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const StarsContainer = styled.div`
  position: relative;
  width: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
