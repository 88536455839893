import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService, FieldType } from 'src/services';

export const CatalogExplorerContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('CatalogFormContainer/NavigateTo'),
  getDataFields: createAsyncThunk(
    'MerchandisingRulesListContainer/GetDataFields',
    async (
      { shopId, fieldType }: { shopId: number; fieldType?: FieldType },
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        return { dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
