import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const TagsSectionStyled = styled.div`
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};

  padding: 8px 16px;
`;

export const TitleTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const TagsContainerStyled = styled.div`
  max-height: 162px;
  overflow-y: scroll;
`;

export const TagStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 90%;
  width: fit-content;
  height: 24px;

  .ellipsis-with-tooltip {
    color: ${({ theme }) => theme.palette.custom['black-10']};
  }
`;

export const TagIconStyled = styled(Icon)`
  max-width: 12px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;
