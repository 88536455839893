import React, { useState } from 'react';
import { ResetPasswordForm, ResetPasswordFormSuccess } from './components';
import { LoginLayout } from '../Login';

interface ResetPasswordContainerProps {
  goToLogin: () => void;
  isCreatingInitialUserPassword: boolean;
}

export const ResetPasswordContainer = ({
  goToLogin,
  isCreatingInitialUserPassword,
}: ResetPasswordContainerProps): JSX.Element => {
  const [resetSuccess, setResetSuccess] = useState(false);

  return (
    <LoginLayout>
      {resetSuccess ? (
        <ResetPasswordFormSuccess
          goToLogin={goToLogin}
          isCreatingInitialUserPassword={isCreatingInitialUserPassword}
        />
      ) : (
        <ResetPasswordForm onSuccess={() => setResetSuccess(true)} goToLogin={goToLogin} />
      )}
    </LoginLayout>
  );
};
