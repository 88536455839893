import React from 'react';
import { ShopDataField } from 'src/services';
import {
  MANDATORY_DATA_FIELDS_MAP,
  MANDATORY_ORDERED_TABLE_COLUMN_VALUES,
} from '../categoryExplorer.config';
import { TableHeadRow } from './CatalogExplorerTable.types';
import { MANDATORY_COLUMNS } from './CatalogExplorerTable.config';
import { getLanguageCodeByLocale, sortArrayByLocale } from 'src/utils';

interface UseDynamicTableColumnsProps {
  dataFieldsMapByName: Record<string, ShopDataField>;
  locale?: string;
}

type UseDynamicTableColumnsResult = {
  tableColumnsObject: Record<string, TableHeadRow>;
  tableColumnsOrderedList: TableHeadRow[];
};

export const useDynamicTableColumns = ({
  dataFieldsMapByName,
  locale = 'en_US',
}: UseDynamicTableColumnsProps): UseDynamicTableColumnsResult => {
  const dynamicColumnsObject = React.useMemo(() => {
    const fieldsColumns = Object.values(dataFieldsMapByName).reduce(
      (prev, field) => {
        if (MANDATORY_DATA_FIELDS_MAP[field.name]) {
          return prev;
        }

        return {
          ...prev,
          [field.name]: {
            header: field.displayName,
            accessor: field.name,
            minWidth: 64,
          },
        };
      },
      {} as Record<string, TableHeadRow>
    );

    return {
      ...fieldsColumns,
    };
  }, [dataFieldsMapByName]);

  // Updated column header according to the shop data fields
  const mandatoryColumnsWithDataFieldDisplayNames = React.useMemo(() => {
    return Object.values(MANDATORY_COLUMNS).reduce((prev, next) => {
      const displayName = dataFieldsMapByName[next.accessor]?.displayName;

      prev[next.accessor] = displayName ? { ...next, header: displayName } : next;

      return prev;
    }, {});
  }, [MANDATORY_COLUMNS, dataFieldsMapByName]);

  const tableColumnsObject = React.useMemo(
    () => ({ ...mandatoryColumnsWithDataFieldDisplayNames, ...dynamicColumnsObject }),
    [dynamicColumnsObject]
  );

  const tableColumnsOrderedList = React.useMemo(() => {
    const sortedDynamicColumnsList = Object.values(dynamicColumnsObject).sort((a, b) => {
      return sortArrayByLocale({
        a: a.header,
        b: b.header,
        languageCode: getLanguageCodeByLocale(locale),
        isAscending: true,
      });
    });

    const sortedDynamicColumnKeys = sortedDynamicColumnsList.map(column => column.accessor);

    const allColumnsOrderedKeys = [
      ...MANDATORY_ORDERED_TABLE_COLUMN_VALUES,
      ...sortedDynamicColumnKeys,
    ];

    const allOrderedColumnsList: TableHeadRow[] = allColumnsOrderedKeys.map(
      columnKey => tableColumnsObject[columnKey]
    );

    return allOrderedColumnsList;
  }, [tableColumnsObject]);

  return {
    tableColumnsObject,
    tableColumnsOrderedList,
  };
};
