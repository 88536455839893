import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { editCustomInspirationsGalleryPageActions } from 'src/components-bl/Galleries';
import {
  shopApiKeysSettingsActions,
  shopApiKeysSettingsModalContainerActions,
} from 'src/containers';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';

export interface IApiKeysReducerState {
  apiKeys: IApiKey[];
  selectedApiKey: IApiKey | null;
}

const initialState: IApiKeysReducerState = {
  apiKeys: [],
  selectedApiKey: null,
};

export const apiKeysReducer = createReducer(initialState, builder => {
  builder.addCase(shopApiKeysSettingsModalContainerActions.getApiKey.fulfilled, (state, action) => {
    return {
      ...state,
      selectedApiKey: action.payload,
    };
  });
  builder.addCase(shopApiKeysSettingsModalContainerActions.resetSelectedApiKey, state => {
    return {
      ...state,
      selectedApiKey: null,
    };
  });
  builder.addCase(editCustomInspirationsGalleryPageActions.resetApiKeys, state => {
    return {
      ...state,
      apiKeys: [],
    };
  });
  builder.addMatcher(
    isAnyOf(
      shopApiKeysSettingsActions.getApiKeys.fulfilled,
      editCustomInspirationsGalleryPageActions.getApiKeys.fulfilled
    ),
    (state, action) => {
      return {
        ...state,
        apiKeys: action.payload,
      };
    }
  );
});
