import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';
import { Auth } from 'src/services';

const authService = new Auth();

/* --= Methods =-- */

export const toastMiddlewareActions = {
  userLocked: createAction('ToastMiddleware/UserLocked'),
  sessionExpired: createAction('ToastMiddleware/SessionExpired'),
  forceLogout: createAsyncThunk(
    'ToastMiddleware/ForceLogout',
    async ({ errorKey }: { errorKey?: ValidationErrorKey }, { rejectWithValue, dispatch }) => {
      try {
        await authService.logout();

        if (errorKey === ValidationErrorKey.AuthUserLocked) {
          dispatch(toastMiddlewareActions.userLocked());
        } else {
          dispatch(toastMiddlewareActions.sessionExpired());
        }
        return;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
