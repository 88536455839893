import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { baseAppRouteMap } from 'src/app-routes';
import { Dispatch } from 'src/components-bl';
import { LoginContainer, ResetPasswordContainer, ToastManager } from 'src/containers';
import { rootContainerActions } from '../Actions';

export interface UnauthorizedUserComponentsProps {
  dispatch: Dispatch;
}

export const UnauthorizedUserComponents = ({
  dispatch,
}: UnauthorizedUserComponentsProps): JSX.Element => {
  const goToLogin = useCallback(() => {
    dispatch(rootContainerActions.navigateTo({ navigateTo: baseAppRouteMap.login.path }));
  }, [dispatch]);

  return (
    <>
      <ToastManager />
      <Switch>
        <Route exact path={baseAppRouteMap.login.path}>
          <LoginContainer />
        </Route>
        <Route exact path={baseAppRouteMap.resetPassword.path}>
          <ResetPasswordContainer goToLogin={goToLogin} isCreatingInitialUserPassword={false} />
        </Route>
        <Route exact path={baseAppRouteMap.createInitialPassword.path}>
          <ResetPasswordContainer goToLogin={goToLogin} isCreatingInitialUserPassword />
        </Route>
        <Redirect to={baseAppRouteMap.login.path} />
      </Switch>
    </>
  );
};
