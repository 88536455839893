import { UserTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { UsersMapper } from './users-mapper';
import { CreateUserArgs, ResetPasswordArgs, UpdateUserArgs } from './types';
import { Buffer } from 'buffer';

export class Users extends ApiServiceBase {
  constructor() {
    super('users');
  }

  async getUser(userId: number): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/${userId}`;
    const response = await this.httpService.get({ url });
    return UsersMapper.map(response);
  }

  async createUser({ userData, accountId }: CreateUserArgs): Promise<UserTypes.User> {
    const data = UsersMapper.getCreateUserPayload({ userData, accountId });
    const response = await this.httpService.post({ url: this.serviceBaseUri, data });
    return UsersMapper.map(response);
  }

  async updateUser({ userData, accountId }: UpdateUserArgs): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/${userData.userId}`;
    const data = UsersMapper.getUpdateUserPayload({ userData, accountId });
    const response = await this.httpService.put({ url, data });
    return UsersMapper.map(response);
  }

  async deleteUser(userId: number): Promise<void> {
    const url = `${this.serviceBaseUri}/${userId}`;
    await this.httpService.delete({ url });
  }

  async forgotPassword(email: string): Promise<void> {
    const url = `${this.serviceBaseUri}/forgot_password`;
    const data = UsersMapper.getForgotPasswordPayload(email);
    await this.httpService.post({ url, data });
  }

  async resetPassword({ newPassword, resetToken }: ResetPasswordArgs): Promise<string> {
    const url = `${this.serviceBaseUri}/reset_unauth_password`;
    const response = await this.httpService.post({
      url,
      data: {
        newPassword: Buffer.from(newPassword.trim(), 'utf8').toString('base64'),
        resetToken,
      },
    });
    return UsersMapper.mapSuccessMessage(response);
  }
}
