import { Store } from 'redux';
import { NotificationType } from 'src/components-dummy';
import { toastActions } from 'src/containers';
import { rootContainerActions } from 'src/root-container/Actions';
import { APITypes, HttpStatusCode } from 'src/services';
import { errorActionsBlackList, actionsToIgnoreUnauthorized } from './actions-blacklist';
import { AnyErrorAction } from './types';
import { getErrorMessageKey } from './util';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';
import { toastMiddlewareActions } from 'src/middleware-actions';

const httpCodesForLogouts = new Set([HttpStatusCode.Unauthorized]);

function shouldForceLogout(action: AnyErrorAction, code: number | undefined): boolean {
  const isCriticalError = action.type === rootContainerActions.initApp.rejected.type;

  return !!(
    isCriticalError ||
    (code && !actionsToIgnoreUnauthorized.has(action.type) && httpCodesForLogouts.has(code))
  );
}

export function errorActionsHandler(action: AnyErrorAction, store: Store): void {
  // If an action was cancelled, it means it's not relevant and we don't need to notify anything to user.
  if (action.meta?.aborted) {
    return;
  }

  const code = (action.payload?.error as APITypes.ApiError | undefined)?.code;
  const errorKey = (action.payload?.error as APITypes.ApiError | undefined)?.errorKey as
    | ValidationErrorKey
    | undefined;

  const isForceLogoutRequired = shouldForceLogout(action, code);

  const shouldCreateToast = !errorActionsBlackList.has(action.type) && !isForceLogoutRequired;
  if (shouldCreateToast) {
    const messageKey = getErrorMessageKey(action);
    if (messageKey) {
      store.dispatch(
        toastActions.enqueue({
          toast: {
            errorKey,
            messageKey,
            type: NotificationType.Error,
            timestamp: new Date().getTime(),
            customMessage: (action.payload as any)?.customMessage,
          },
        })
      );
    }
  }

  if (isForceLogoutRequired) {
    store.dispatch(toastMiddlewareActions.forceLogout({ errorKey }) as any);
  }
}
