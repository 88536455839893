import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Dispatch } from 'src/components-bl';
import { RoutedComponentProps } from 'src/app-routes';
import { Button, Page } from 'src/components-dummy';
import { IShopCatalog, ShopCatalogAddedMethod } from 'src/services';
import { PermissionAction, User } from 'src/services/src/service/types/users';
import { catalogsListPageActions } from '../../CatalogsListPage.actions';
import { guidelinesLink } from './guidelinesLink';
import { CatalogsListPageContent } from './CatalogsListPageContent';
import { ActionButtonsWrapper, PageStyled, PageTitlesWrapper } from './CatalogsListPage.styles';

export interface CatalogsListPageProps extends RoutedComponentProps {
  catalogs: IShopCatalog[] | undefined;
  dispatch: Dispatch;
  shopId: number;
  loggedInUser?: User;
}

export const CatalogsListPage = ({
  catalogs,
  dispatch,
  shopId,
  permittedRouteMap,
  loggedInUser,
}: CatalogsListPageProps): JSX.Element => {
  const [fetchCatalogsInProgress, setFetchCatalogsInProgress] = useState(false);

  const readOnly = useMemo(
    () =>
      !loggedInUser ||
      !permittedRouteMap.catalogManager?.permissions ||
      permittedRouteMap.catalogManager.permissions[loggedInUser.role] === PermissionAction.Read,
    [permittedRouteMap, loggedInUser]
  );

  const navigateToConnectCatalog = () => {
    if (shopId && permittedRouteMap.connectExistingCatalog)
      dispatch(
        catalogsListPageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.connectExistingCatalog.path, { shopId }),
        })
      );
  };

  const navigateToCreateNewCatalog = () => {
    if (shopId && permittedRouteMap.catalogManagerCreateCatalog)
      dispatch(
        catalogsListPageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.catalogManagerCreateCatalog.path, {
            shopId,
          }),
        })
      );
  };

  const navigateToEditCatalog = useCallback(
    (id: string) => {
      if (!shopId) return;

      const catalog = (catalogs || [])?.find(item => item.id === id);

      if (
        catalog?.addedMethod === ShopCatalogAddedMethod.Existing &&
        permittedRouteMap.editExistingCatalog
      ) {
        dispatch(
          catalogsListPageActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.editExistingCatalog.path, { shopId, id }),
          })
        );
      }

      if (
        catalog?.addedMethod === ShopCatalogAddedMethod.New &&
        permittedRouteMap.catalogManagerUpdateCatalog
      ) {
        dispatch(
          catalogsListPageActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.catalogManagerUpdateCatalog.path, {
              shopId,
              catalogName: catalog?.name,
            }),
          })
        );
      }
    },
    [shopId, catalogs, dispatch]
  );

  const navigateToFeedUploadLog = useCallback(
    (id: string) => {
      if (!shopId) return;

      const catalog = (catalogs || [])?.find(item => item.id === id);

      if (permittedRouteMap.catalogManagerFeedUploadLog) {
        dispatch(
          catalogsListPageActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.catalogManagerFeedUploadLog.path, {
              shopId,
              catalogName: catalog?.name,
            }),
          })
        );
      }
    },
    [shopId, catalogs, dispatch]
  );

  const navigateToViewCatalog = useCallback(
    (id: string) => {
      if (!shopId) return;

      const catalog = (catalogs || [])?.find(item => item.id === id);

      if (permittedRouteMap.catalogManagerFeedUploadLog) {
        dispatch(
          catalogsListPageActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.catalogExplorer.path, {
              shopId,
              catalogName: catalog?.name,
            }),
          })
        );
      }
    },
    [shopId, catalogs, dispatch]
  );

  const onSetCatalogAsPrimary = useCallback(
    catalogId => {
      if (shopId) {
        dispatch(
          catalogsListPageActions.setCatalogAsPrimary({
            shopId,
            id: catalogId,
          })
        );
      }
    },
    [shopId, dispatch]
  );

  const onCatalogDelete = useCallback(
    catalogId => {
      if (shopId) {
        dispatch(
          catalogsListPageActions.deleteCatalog({
            shopId,
            id: catalogId,
          })
        );
      }
    },
    [shopId, dispatch]
  );

  const shouldShowActionButtons = useMemo(() => {
    if (!catalogs || readOnly) return false;

    return catalogs.length > 0;
  }, [catalogs, readOnly]);

  useEffect(() => {
    setFetchCatalogsInProgress(true);
    (dispatch(catalogsListPageActions.getCatalogs({ shopId })) as any)
      .unwrap()
      .then(() => {
        setFetchCatalogsInProgress(false);
      })
      .catch(() => {
        setFetchCatalogsInProgress(false);
      });
  }, [shopId, dispatch]);

  return (
    <PageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <Page.Title>Catalogue Manager</Page.Title>
          <Page.SubTitle>
            Manage and review your catalogue setup and health.{' '}
            <a href={guidelinesLink} target='_blank' rel='noreferrer'>
              Learn more about Catalogues ↗
            </a>
          </Page.SubTitle>
        </PageTitlesWrapper>
        {shouldShowActionButtons && (
          <ActionButtonsWrapper>
            <Button variant='tertiary' onClick={navigateToConnectCatalog}>
              Connect Existing Catalogue
            </Button>
            {/* 
            // Attention!!!
            // currently removed - feature (create catalog) is not being used
            <Button variant='primary' onClick={navigateToCreateNewCatalog}>
              Add new Catalogue
            </Button> */}
          </ActionButtonsWrapper>
        )}
      </Page.Header>
      <Page.Content>
        <CatalogsListPageContent
          catalogs={catalogs}
          fetchCatalogsInProgress={fetchCatalogsInProgress}
          navigateToConnectCatalog={navigateToConnectCatalog}
          navigateToCreateNewCatalog={navigateToCreateNewCatalog}
          navigateToViewCatalog={navigateToViewCatalog}
          onSetCatalogAsPrimary={onSetCatalogAsPrimary}
          onCatalogDelete={onCatalogDelete}
          navigateToEditCatalog={navigateToEditCatalog}
          navigateToFeedUploadLog={navigateToFeedUploadLog}
          readOnly={readOnly}
        />
      </Page.Content>
    </PageStyled>
  );
};
