import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';

/**
 * Page - Main title
 */
export const PageTitleWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 40px;
  gap: 12px;
`;

export const PageMainTitleRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageMainTitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  max-height: 27px;
`;

export const BackIconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-right: 20px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const PrimaryCatalogStyled = styled.span`
  padding: 8px;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.custom['primary-light']};
  margin-left: 16px;
`;

export const TitleContextStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

/**
 * Page - Sub title
 */
export const PageSubTitleRowStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-left: 32px;
`;

export const SeparatorBulletStyled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.custom['gray-60']};
  margin: 0 5px;
  align-self: center;
`;
