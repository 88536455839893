import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableWrapperStyled = styled.div`
  width: fit-content;
  min-width: 100%;
`;

export const HeadRowStyled = styled(TableV2.HeadRow)`
  align-items: center;
`;

export const TableBodyRowCellAlignedCenterStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
`;

export const ImageWrapperStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  padding: 0;
  margin-left: -11px;
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  min-width: 165px;
`;

export const ProductImageStyled = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

export const ProductNumberStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.custom['gray-30']};
`;
