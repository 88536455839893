import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const tagImageModalActions = {
  navigateTo: createAction<{ navigateTo: string }>('TagImageModal/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('TagImageModal/NotifyIsDirty'),
  updateGallery: createAsyncThunk(
    'TagImageModal/UpdateGallery',
    async (
      {
        shopId,
        galleryId,
        payload,
      }: Parameters<typeof galleriesService.updateCustomInspirationsGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.updateCustomInspirationsGallery({
          shopId,
          galleryId,
          payload,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  startImageTagsDetection: createAsyncThunk(
    'TagImageModal/StartImageTagsDetection',
    async (
      parameters: Parameters<typeof galleriesService.startImageTagsDetection>[0],
      { rejectWithValue }
    ) => {
      try {
        await galleriesService.startImageTagsDetection(parameters);
        return;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
