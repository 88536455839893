import { DomainEntityPath } from '../types';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';

type DeleteExperimentPayload = { experimentId: string; shopId: number };
type UpdateExperimentPayload = DeleteExperimentPayload & { experimentName: string };

export enum ExperimentEventTopicNames {
  Create = 'experiments:create',
  Delete = 'experiments:delete',
  Update = 'experiments:update',
  Start = 'experiments:start',
  Stop = 'experiments:stop',
}

export type ExperimentUpdateEvent = DomainEvent<UpdateExperimentPayload, ExperimentEventTopicNames>;
export type ExperimentDeleteEvent = DomainEvent<DeleteExperimentPayload, ExperimentEventTopicNames>;

class ExperimentWebSocketService extends DomainEventsWebSocketServiceBase<ExperimentEventTopicNames> {
  domainEntity = DomainEntityPath.Experiment;

  subscribeToExperimentCreateEvents(
    onExperimentCreate: (payload: ExperimentUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: ExperimentEventTopicNames.Create,
      callback: (response: ExperimentUpdateEvent) => {
        onExperimentCreate(response);
      },
    });
  }

  subscribeToExperimentUpdateEvents(
    onExperimentUpdate: (payload: ExperimentUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: ExperimentEventTopicNames.Update,
      callback: (response: ExperimentUpdateEvent) => {
        onExperimentUpdate(response);
      },
    });
  }

  subscribeToExperimentStartEvents(
    onExperimentStart: (payload: ExperimentUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: ExperimentEventTopicNames.Start,
      callback: (response: ExperimentUpdateEvent) => {
        onExperimentStart(response);
      },
    });
  }

  subscribeToExperimentStopEvents(
    onExperimentStop: (payload: ExperimentUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: ExperimentEventTopicNames.Stop,
      callback: (response: ExperimentUpdateEvent) => {
        onExperimentStop(response);
      },
    });
  }

  subscribeToExperimentDeleteEvents(
    onExperimentDelete: (payload: ExperimentDeleteEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: ExperimentEventTopicNames.Delete,
      callback: onExperimentDelete,
    });
  }
}

export const experimentsWebSocketService = new ExperimentWebSocketService();
