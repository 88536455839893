import React, { useCallback, useMemo, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import {
  AddImagesButtonStyled,
  ContentHeaderRowStyled,
  GalleryPreviewStyled,
  HeaderButtonsContainerStyled,
  SkeletonGrid,
  SkeletonStyled,
} from './GalleryPreview.styles';
import { EmptyState, GalleryGrid } from './components';
import { CustomInspirationGalleryDraft } from '../../types';
import { ExpandableSection } from '../../../TagImageModal/components/ImageSettings/components';

const BASE_PREVIEW_URL = 'https://search.syte.ai/previewGalleries.html';

function LoadingSkeleton() {
  return (
    <SkeletonGrid>
      <SkeletonStyled height={220} width={220} variant='rounded' />
      <SkeletonStyled height={220} width={220} variant='rounded' />
      <SkeletonStyled height={220} width={220} variant='rounded' />
      <SkeletonStyled height={220} width={220} variant='rounded' />
      <SkeletonStyled height={220} width={220} variant='rounded' />
      <SkeletonStyled height={220} width={220} variant='rounded' />
    </SkeletonGrid>
  );
}

interface GalleryPreviewProps {
  gallery: CustomInspirationGalleryDraft | undefined;
  galleryId: string;
  navigateToAddImages: () => void;
  navigateToImageSettings: (imageId: string, sections: ExpandableSection[]) => void;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
  dispatch: Dispatch;
  shopId: number;
  shopApiKey?: IApiKey;
}

export const GalleryPreview = ({
  gallery,
  galleryId,
  onChange,
  navigateToAddImages,
  navigateToImageSettings,
  dispatch,
  shopId,
  shopApiKey,
}: GalleryPreviewProps): JSX.Element => {
  const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0);

  const isLoading = useMemo(
    () => !gallery || numberOfLoadedImages !== gallery.images.length,
    [gallery, numberOfLoadedImages]
  );

  const galleryHasImages = useMemo(
    () => gallery?.images && gallery.images.length > 0,
    [gallery?.images]
  );

  const onImageLoaded = useCallback(() => {
    setNumberOfLoadedImages(loadedImagesCount => loadedImagesCount + 1);
  }, [setNumberOfLoadedImages]);

  const onRemoveImage = useCallback(
    (imageId: string) => {
      const filteredImages = gallery?.images.filter(image => image.id !== imageId);
      onChange({ images: filteredImages });
      setNumberOfLoadedImages(loadedImagesCount => loadedImagesCount - 1);
    },
    [gallery?.images, onChange]
  );

  const navigateToEditTags = useCallback(
    (imageId: string) => {
      navigateToImageSettings(imageId, [ExpandableSection.ProductTags]);
    },
    [navigateToImageSettings]
  );

  const navigateToEditImageTexts = useCallback(
    (imageId: string) => {
      navigateToImageSettings(imageId, [ExpandableSection.AltText, ExpandableSection.Caption]);
    },
    [navigateToImageSettings]
  );

  const onPreviewGalleryClick = useCallback(() => {
    const url = `${BASE_PREVIEW_URL}?galleryId=${galleryId}&shopId=${shopId}&sig=${shopApiKey?.key}`;
    window.open(url, '_blank');
  }, [galleryId, shopId, shopApiKey]);

  const content = useMemo(() => {
    if (!galleryHasImages) {
      return <EmptyState navigateToAddImages={navigateToAddImages} hide={isLoading} />;
    }

    return gallery?.layout.type === CustomInspirationsGalleryLayoutType.Grid ? (
      <GalleryGrid
        gallery={gallery as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Grid>}
        onRemoveImage={onRemoveImage}
        onAddTags={navigateToEditTags}
        onImageClick={navigateToEditImageTexts}
        onChange={onChange}
        shopId={shopId}
        dispatch={dispatch}
        onImageLoaded={onImageLoaded}
        hide={isLoading}
      />
    ) : (
      <>TODO CAROUSEL GALLERY</>
    );
  }, [
    gallery,
    navigateToImageSettings,
    navigateToEditTags,
    onChange,
    navigateToAddImages,
    galleryHasImages,
    isLoading,
  ]);

  return (
    <GalleryPreviewStyled>
      <ContentHeaderRowStyled>
        <Typography variant={TypographyVariant.ExtraSmallBold} type={TypographyType.Heading}>
          {gallery?.displayTitle || ''}
        </Typography>
        {galleryHasImages && (
          <HeaderButtonsContainerStyled>
            {shopApiKey && (
              <Button
                variant='secondary'
                startIcon={AvailableIcons.PreviewButton}
                onClick={onPreviewGalleryClick}
              >
                Preview
              </Button>
            )}
            <AddImagesButtonStyled
              onClick={navigateToAddImages}
              variant='primary'
              startIcon={AvailableIcons.Plus}
            >
              Add Images
            </AddImagesButtonStyled>
          </HeaderButtonsContainerStyled>
        )}
      </ContentHeaderRowStyled>

      {isLoading && <LoadingSkeleton />}
      {content}
    </GalleryPreviewStyled>
  );
};
