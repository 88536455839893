import React from 'react';
import {
  AvailableIcons,
  Backdrop,
  Icon,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  StarsContainer,
  StartAnimated,
  TagsDetectionBackdropContentWrapper,
  TypographyStyled,
} from './TagsDetectionBackdropLoader.styles';

interface TagsDetectionBackdropLoaderProps {
  show: boolean;
}

export const TagsDetectionBackdropLoader = ({
  show,
}: TagsDetectionBackdropLoaderProps): JSX.Element | null => {
  return show ? (
    <Backdrop sx={{ zIndex: 2000 }}>
      <TagsDetectionBackdropContentWrapper>
        <StarsContainer>
          <StartAnimated style={{ top: 0, right: '20%', animationDelay: '0.2s' }}>
            <Icon name={AvailableIcons.YellowStar} />
          </StartAnimated>
          <StartAnimated style={{ top: '35%', left: 0, animationDelay: '0.4s' }}>
            <Icon name={AvailableIcons.YellowStar} />
          </StartAnimated>
          <StartAnimated style={{ bottom: 0, right: 0 }}>
            <Icon name={AvailableIcons.YellowStar} />
          </StartAnimated>
        </StarsContainer>
        <TypographyStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
          Stay tuned, generating suggestion for you...
        </TypographyStyled>
      </TagsDetectionBackdropContentWrapper>
    </Backdrop>
  ) : null;
};
