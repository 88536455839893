import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { isEqual } from 'lodash';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Button } from 'src/components-dummy';
import { CustomInspirationsGallery, GalleryType } from 'src/services';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import { editCustomInspirationsGalleryPageActions } from './Actions';
import { GalleryPreview, EditGallerySideBar } from './components';
import {
  EditCustomInspirationsGalleryPageStyled,
  PreviewFooter,
  PreviewLayout,
  SideBarLayout,
  OverlayModalStyled,
} from './EditCustomInspirationsGalleryPage.styles';
import { GalleryMapper } from './mapper';
import { CustomInspirationGalleryDraft } from './types';
import { ExpandableSection } from '../TagImageModal/components/ImageSettings/components';
import { getQueryStringParams } from '../common/utils';

interface EditCustomInspirationsGalleryPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  galleryId: string;
  gallery?: CustomInspirationsGallery;
  hasDraft: boolean;
  shopApiKey?: IApiKey;
}

export function EditCustomInspirationsGalleryPage({
  shopId,
  dispatch,
  galleryId,
  gallery,
  hasDraft,
  shopApiKey,
  permittedRouteMap,
}: EditCustomInspirationsGalleryPageProps): JSX.Element {
  const mappedInitialGallery = useMemo(() => GalleryMapper.mapGalleryToDraft(gallery), [gallery]);

  const [draftGallery, setDraftGallery] = useState<CustomInspirationGalleryDraft | undefined>(
    mappedInitialGallery
  );
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const isDraftEqualToSource = isEqual(mappedInitialGallery, draftGallery);
    setIsDirty(!isDraftEqualToSource);
  }, [draftGallery, mappedInitialGallery]);

  useEffect(() => {
    dispatch(editCustomInspirationsGalleryPageActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  const onChange = useCallback(
    (partialGallery: Partial<CustomInspirationGalleryDraft>) => {
      if (draftGallery) {
        setDraftGallery({ ...draftGallery, ...partialGallery });
      }
    },
    [draftGallery, setDraftGallery]
  );

  const navigateToList = useCallback(() => {
    if (!permittedRouteMap.galleriesList) return;

    dispatch(
      editCustomInspirationsGalleryPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.galleriesList.path, { shopId }),
      })
    );
  }, [shopId, permittedRouteMap.galleriesList, dispatch]);

  const navigateToAddImages = useCallback(() => {
    if (!permittedRouteMap.addImagesToCustomInspirationsGallery) return;

    dispatch(
      editCustomInspirationsGalleryPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.addImagesToCustomInspirationsGallery.path, {
          shopId,
          galleryId,
        }),
      })
    );
  }, [shopId, permittedRouteMap.addImagesToCustomInspirationsGallery, dispatch, galleryId]);

  const navigateToImageSettings = useCallback(
    (imageId: string, openedSections: ExpandableSection[]) => {
      if (!permittedRouteMap.tagImageInCustomInspirationsGallery) {
        return;
      }

      const baseUrl = generatePath(permittedRouteMap.tagImageInCustomInspirationsGallery.path, {
        shopId,
        galleryId,
        imageId,
      });

      const queryParams = getQueryStringParams(openedSections);

      const targetUrl = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;

      dispatch(
        editCustomInspirationsGalleryPageActions.navigateTo({
          navigateTo: targetUrl,
        })
      );
    },
    [dispatch, permittedRouteMap.tagImageInCustomInspirationsGallery, galleryId, shopId]
  );

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        await (
          dispatch(
            editCustomInspirationsGalleryPageActions.getGallery({
              shopId,
              galleryId,
              galleryType: GalleryType.CustomInspirations,
            })
          ) as any
        ).unwrap();
      } catch (error) {
        console.error(error);
        navigateToList();
      }
    };

    fetchGallery();
    dispatch(editCustomInspirationsGalleryPageActions.getApiKeys({ shopId }));

    return () => {
      dispatch(editCustomInspirationsGalleryPageActions.resetCurrentGallery());
      dispatch(editCustomInspirationsGalleryPageActions.resetApiKeys());
    };
  }, [shopId, galleryId, dispatch, navigateToList]);

  useEffect(() => {
    setDraftGallery(mappedInitialGallery);
  }, [mappedInitialGallery]);

  const onSaveGallery = useCallback(() => {
    if (draftGallery) {
      dispatch(
        editCustomInspirationsGalleryPageActions.updateGallery({
          shopId,
          galleryId,
          payload: draftGallery,
        })
      );
    }
  }, [dispatch, shopId, galleryId, draftGallery]);

  return (
    <OverlayModalStyled show fullscreen={!hasDraft}>
      <EditCustomInspirationsGalleryPageStyled>
        <SideBarLayout>
          <EditGallerySideBar
            gallery={draftGallery}
            onChange={onChange}
            galleryId={galleryId}
            dispatch={dispatch}
            navigateToList={navigateToList}
          />
        </SideBarLayout>
        <PreviewLayout>
          <GalleryPreview
            gallery={draftGallery}
            galleryId={galleryId}
            onChange={onChange}
            navigateToImageSettings={navigateToImageSettings}
            navigateToAddImages={navigateToAddImages}
            dispatch={dispatch}
            shopId={shopId}
            shopApiKey={shopApiKey}
          />
          <PreviewFooter>
            <Button variant='secondary' onClick={navigateToList}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onSaveGallery} disabled={!isDirty}>
              Save Gallery
            </Button>
          </PreviewFooter>
        </PreviewLayout>
      </EditCustomInspirationsGalleryPageStyled>
    </OverlayModalStyled>
  );
}
