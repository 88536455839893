import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { experimentService } from 'src/services';
import {
  AppHeaderActionTypes,
  AppHeaderNavigateToActionPayload,
  AppHeaderNavigateToAction,
} from './types';

export const appHeaderActions = {
  navigateTo(payload: AppHeaderNavigateToActionPayload): AppHeaderNavigateToAction {
    return {
      type: AppHeaderActionTypes.NavigateTo,
      payload,
    };
  },
  experimentCreated: createAsyncThunk(
    'AppHeader/ExperimentCreated',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const experiments = await experimentService.getExperiments({
          shopId,
        });

        return { experiments };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
  experimentUpdated: createAsyncThunk(
    'AppHeader/ExperimentUpdated',
    async (
      { shopId, experimentId }: { shopId: number; experimentId: string },
      { rejectWithValue }
    ) => {
      try {
        const experiments = await experimentService.getExperiments({
          shopId,
        });

        return { experiments, experimentId };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
  experimentDeleted: createAction<{ experimentId: string; shopId: number }>(
    'AppHeader/ExperimentDeleted'
  ),

  experimentNotification: createAction<{ customMessage: string }>(
    `AppHeader/ExperimentNotification`
  ),
};
