import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import {
  SideNavContainer,
  AppHeader,
  LogHistoryPanelContainer,
  Team,
  AccountListContainer,
  MyProfileContainer,
  PageNotFoundContainer,
  ShopRoutes,
  useDeepTagReportsWS,
  AccountPlansContainer,
  ToastManager,
  ReviewVersionContainer,
} from 'src/containers';
import { UserTypes } from 'src/services';
import {
  AppRoute,
  AppSwitch,
  RouteSettings,
  RoutedComponentProps,
  baseAppRouteMap,
} from 'src/app-routes';
import { UnsavedChangesModal } from 'src/components-bl/UnsavedChangesModal';
import { useFeatureTogglesWS, useDataFieldsWS } from 'src/components-bl';
import { useMerchandisingRulesWS } from 'src/containers/MerchandisingRules';
import { ShopFeature } from 'src/app-types';
import { useInitApp, useInjectHotjar, useAppTitle } from './hooks';
import { RootReducerState } from '../app-state-types';
import { rootContainerActions } from './Actions';

import { DeepTagReportsModalsSwitch } from '../containers/DeepTagReports/components/DeepTagReportsModalsSwitch';
import { useDomainEventsWS } from './useDomainEventsWS';
import { useUserLocationWS } from './useUserLocationWS';
import { CreateShopModalContainer } from 'src/containers/CreateShopModalContainer/CreateShopModalContainer';
import { ShopListContainer } from 'src/containers/ShopListContainer/ShopListContainer';

export interface AppContentProps extends RoutedComponentProps {
  fallback: string;
  user: UserTypes.User;
  // eslint-disable-next-line react/no-unused-prop-types
  rootRoutes: RouteSettings[];
  enabledShopFeatures: Set<ShopFeature>;
}

const skipLoggedInUserRedirectRoutes = new Set([
  '/',
  baseAppRouteMap.login.path,
  baseAppRouteMap.resetPassword.path,
  baseAppRouteMap.createInitialPassword.path,
]);

export const AppContent = ({
  fallback,
  permittedRouteMap,
  user,
  enabledShopFeatures,
}: AppContentProps): JSX.Element => {
  useInitApp({ user });

  const { selectedVariantId, confirm, shopId, experimentsEnabled, firstRoute } = useSelector(
    (state: RootReducerState) => ({
      confirm: state.global.confirm,
      shopId: state.shop.current?.shopId,
      experimentsEnabled: state.shop.featureToggles?.experiments.enabled,
      selectedVariantId: state.experiments.selectedVariantId,
      firstRoute: state.global.firstRoute,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const featureTogglesLoaded = experimentsEnabled !== undefined; // prevents duplicate call.
    if (
      shopId &&
      featureTogglesLoaded &&
      (user.role === UserTypes.UserRoles.SyteAdmin || experimentsEnabled)
    ) {
      dispatch(rootContainerActions.checkExperimentRunning(shopId));
    }

    return () => {
      dispatch(rootContainerActions.resetRunningExperiment());
    };
  }, [shopId, experimentsEnabled]);

  const handleConfirm = (leave: boolean) => {
    const data = { confirm: { leave, action: confirm } };
    dispatch(rootContainerActions.confirmNav(data));
  };

  const currentPath = useLocation().pathname;

  useAppTitle();

  const routePathName = currentPath.split('/').pop();

  useEffect(() => {
    const shouldRedirectUser =
      firstRoute && firstRoute !== currentPath && !skipLoggedInUserRedirectRoutes.has(firstRoute);

    if (shouldRedirectUser) {
      // try to redirect user to first url before login
      dispatch(rootContainerActions.navigateTo({ navigateTo: firstRoute }));
    }

    dispatch(rootContainerActions.resetFirstRoute());
    return () => {
      dispatch(rootContainerActions.navigateTo({ navigateTo: baseAppRouteMap.login.path }));
    };
  }, []);

  useDomainEventsWS({ dispatch });
  useUserLocationWS({ dispatch });

  useDeepTagReportsWS({ dispatch, shopId });
  useDataFieldsWS({ dispatch, shopId });
  useFeatureTogglesWS({ shopId, dispatch });
  useMerchandisingRulesWS({ shopId, dispatch, selectedVariantId });

  useInjectHotjar({ user });

  return (
    <>
      {/* Modals (app level) */}
      <>
        {/* Routes (modals) */}
        <AppSwitch>
          {shopId && (
            <AppRoute
              route={permittedRouteMap.deepTagReports}
              Component={DeepTagReportsModalsSwitch}
              componentProps={{ permittedRouteMap, enabledShopFeatures }}
              exact={false}
            />
          )}
          <AppRoute
            route={permittedRouteMap.createNewShop}
            Component={CreateShopModalContainer}
            componentProps={{ permittedRouteMap }}
            exact
          />
        </AppSwitch>
        {/* Non Routes (modals) */}
        {confirm && <UnsavedChangesModal handleConfirm={handleConfirm} />}
      </>

      {/* App content (layout) */}
      <div className='appContent'>
        <AppHeader permittedRouteMap={permittedRouteMap} role={user.role} />

        <SideNavContainer permittedRouteMap={permittedRouteMap} role={user.role} />

        <LogHistoryPanelContainer />
        <ReviewVersionContainer />
        <div className={`contentContainer ${routePathName}`}>
          <ToastManager />
          <AppSwitch>
            <AppRoute
              route={permittedRouteMap.accountList}
              Component={AccountListContainer}
              componentProps={{ permittedRouteMap, enabledShopFeatures }}
            />
            <AppRoute
              route={permittedRouteMap.shopList}
              Component={ShopListContainer}
              componentProps={{ permittedRouteMap }}
              exact={false}
            />
            <AppRoute route={permittedRouteMap.team} Component={Team} />
            <AppRoute route={permittedRouteMap.myProfile} Component={MyProfileContainer} />
            <AppRoute route={permittedRouteMap.accountPlans} Component={AccountPlansContainer} />
            {/*
             Container for components that supports '/shops/:shopId' routing
             */}
            <AppRoute
              route={permittedRouteMap.shopRoutes}
              Component={ShopRoutes}
              componentProps={{ permittedRouteMap, enabledShopFeatures }}
              exact={false}
            />
            <Route path={['/', '/login']} exact>
              <Redirect to={fallback} />
            </Route>
            <Route component={PageNotFoundContainer} />,
          </AppSwitch>
        </div>
      </div>
    </>
  );
};
