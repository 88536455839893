import React, { useCallback, useEffect, useRef, useState } from 'react';
import { visualEditorFetchOffersActions } from '../../state';
import { useVisualEditorProvider } from '../../context';
import { useGetDataFieldsToDisplay } from '../../hooks';
import { useDebounce } from '../../../../components-dummy';
import { useAddParamsToRoute } from '../../hooks/useAddParamsToRoute';
import { useSyncParamsWithState } from '../../helpers/use-sync-params-with-state';
import { TextSearchItemsPayload } from '../../types/text-search';
import { AsyncThunkPromise } from '../../../../types';
import { IndexedProductOffers } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import { PreviewFeature } from '../../../../services';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';

interface UseFetchTextSearchOffersProps {
  shopId: number;
  stateController: StateController<string>;
  permittedRouteMap: PermittedRouteMap;
  selectedExperience?: PreviewFeature;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
}

export const useFetchTextSearchOffers = ({
  shopId,
  stateController,
  permittedRouteMap,
  selectedExperience,
  resultsScreenRef,
}: UseFetchTextSearchOffersProps) => {
  const { value, setValue } = stateController;
  const [query, setQuery] = useState(value || '');
  useSyncParamsWithState({ paramName: 'query', setState: setQuery });

  const { dispatchOffersThunk } = useVisualEditorProvider();
  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });
  const thunkPromise = useRef<AsyncThunkPromise<
    IndexedProductOffers,
    TextSearchItemsPayload
  > | null>(null);

  const { fields } = useGetDataFieldsToDisplay({ shopId });

  const fetchTextSearchOffers = useCallback(
    (payload: TextSearchItemsPayload) => {
      thunkPromise.current = dispatchOffersThunk(
        visualEditorFetchOffersActions.getTextSearchItems,
        payload
      );
    },
    [dispatchOffersThunk]
  );

  const fetchOffersDebounced = useDebounce(fetchTextSearchOffers, 300);

  const onValueChange = useCallback(
    (searchValue: string) => {
      setQuery(searchValue);
      setValue(searchValue);
      resultsScreenRef.current?.scrollTo(0, 0);
      fetchOffersDebounced({
        shopId,
        query: searchValue || '+',
        fields,
        colorVariantFields: fields,
        sizeVariantFields: fields,
      });
      addParamsToRoute({ shopperExperience: selectedExperience, query: searchValue });
    },
    [shopId, fetchOffersDebounced, fields, selectedExperience, resultsScreenRef.current]
  );

  useEffect(() => {
    setValue(query);
  }, [query]);

  useEffect(() => {
    if (!query) return;
    fetchOffersDebounced({
      shopId,
      query,
      fields,
      colorVariantFields: fields,
      sizeVariantFields: fields,
    });
  }, [shopId, query, fields, fetchOffersDebounced]);

  return {
    value: query,
    onValueChange,
  };
};
