export enum ShopCatalogAddedMethod {
  Existing = 'existing',
  New = 'new',
}

export enum ShopCatalogVertical {
  Home = 'home',
  General = 'general',
  DIY = 'diy',
  Fashion = 'fashion',
}

export interface IShopCatalog {
  id: string;
  shopId: number;
  name: string;
  isAugmentedSearchCatalog: boolean;
  isPrimary: boolean;
  isMultiLocale: boolean;
  useAllClustersWhenNoBounds: boolean;
  defaultLocale: string;
  availableLocales: {
    languages: string[];
    regions: string[];
  };
  verticals: ShopCatalogVertical[];
  addedMethod: ShopCatalogAddedMethod;
  numberOfIndexedProducts: number;
  numberOfErrors: number;
  errorReport: string;
  lastUpdated: Date | undefined;
}

export interface IShopCatalogAPI extends Omit<IShopCatalog, 'lastUpdated'> {
  lastUpdated: string;
}

export interface ICatalogPlanCreate {
  name: string;
  verticals: ShopCatalogVertical[];
  genders: string[];
  productsFile?: File;
  sftpServerSettings?: IsftpServerSettings;
  wgetServerSettings?: IwgetServicerSettings;
  downloaderType: DownloaderTypesEnum;
  scheduling?: ICatalogPlanSchedulingAPI;
}

export interface IsftpServerSettings {
  type: string;
  host: string;
  port: number;
  path: string;
  authentication: boolean;
  username?: string;
  password?: string;
  fileName: string;
}

export interface IwgetServicerSettings {
  dataSourceURL: string;
  authentication: boolean;
  username?: string;
  password?: string;
}

export interface ICatalogPlanSchedulingAPI {
  dailyTime?: {
    hour: number;
    minutes: number;
  };
  timeIntervals?: {
    hours: number;
    minutes: number;
  };
}

export interface ICatalogPlanAPI {
  name: string;
  verticals: ShopCatalogVertical[];
  genders: string[];
  sftpServerSettings?: IsftpServerSettings;
  wgetServerSettings?: IwgetServicerSettings;
  scheduling: ICatalogPlanSchedulingAPI;
  downloaderType: DownloaderTypesEnum;
}

export type CatalogPlanSelected = ICatalogPlanAPI;

export enum CatalogUploadLogsStatusEnumAPI {
  Success = 'success',
  Failed = 'failed',
  Warning = 'warning',
}

export enum CatalogUploadLogsStatusEnum {
  Success = 'success',
  Failed = 'failed',
  Warning = 'warning',
  Processed = 'processed',
}

export interface ICatalogFeedUploadLogAPI {
  jobId: string;
  status: CatalogUploadLogsStatusEnumAPI;
  startDate: string;
  endDate: string;
  numberOfIndexedProducts?: number;
  numberOfFailedProducts?: number;
  errorReason?: string;
  errorReasonInternal?: string;
  actionRequired?: string;
  actionRequiredInternal?: string;
  hasFeedFileUrl: boolean;
}

export interface ICatalogFeedUploadLog {
  jobId: string;
  status: CatalogUploadLogsStatusEnum;
  startDate: Date;
  endDate: Date;
  numberOfIndexedProducts?: number;
  numberOfFailedProducts?: number;
  errorReason?: string; // Only for errored feed runs
  errorReasonInternal?: string;
  actionRequired?: string; // Only for errored feed runs (action incase of a failed feed run)
  actionRequiredInternal?: string; // Only for errored feed runs (action incase of a failed feed run)
  hasFeedFileUrl: boolean;
}

export interface ICatalogUploadErrorReport {
  sku: string;
  title: string;
  category: string;
  syteCategory: string;
  imageUrl: string;
  errorDescription: string;
  errorDescriptionInternal: string;
  actions: string;
  actionsInternal: string;
}

export interface FilterOption {
  name: string | null;
  count: number;
}

export interface ICatalogErrorReportFilterOptions {
  categories: FilterOption[];
  syteCategories: FilterOption[];
  actions: FilterOption[];
  errorDescriptions: FilterOption[];
}

export interface ICatalogUploadErrorsPagination {
  skip: number;
  limit: number;
}

export interface ICatalogUploadErrorsFilters {
  categories?: string[];
  syteCategories?: string[];
  errorDescriptions?: string[];
  actions?: string[];
}

export type CatalogUploadErrorsPaginationWithFilters = ICatalogUploadErrorsPagination &
  ICatalogUploadErrorsFilters;

export interface ICatalogErrorReportFilters {
  search?: string;
  categories?: string[];
  syteCategories?: string[];
  actions?: string[];
  errorDescriptions?: string[];
}

export interface ICatalogUploadErrorsPaginated {
  totalCount: number;
  data: ICatalogUploadErrorReport[];
  filterOptions: ICatalogErrorReportFilterOptions;
}

export const genderTypes = {
  female: 'female',
  male: 'male',
  girl: 'girl',
  boy: 'boy',
  baby_boy: 'baby_boy',
  baby_girl: 'baby_girl',
  hd: 'hd',
} as const;

export enum DownloaderTypesEnum {
  AWS_S3 = 'aws_s3',
  SFTP = 'sftp',
  FTP = 'ftp',
  WGET = 'wget',
}

/**
 * Catalog Explorer
 */
export interface ICatalogExplorerProductsFilters {
  skip: number;
  limit: number;
  [filterKey: string]: string | number;
}

export interface ICatalogProduct {
  id: string;
  imageUrl: string;
  title: string;
  parent_sku: string;
  color_sku: string;
  sku: string;
  price: number;
  originalPrice: number;
  categories: string[];
  brand: string;
  [key: string]: string | number | string[];
}

export interface CatalogExplorerProductsGetResponse {
  data: { products: ICatalogProduct[] };
  totalFilteredProducts: number;
  totalCatalogProducts: number;
}
