import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { isEqual, isUndefined, omitBy } from 'lodash';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Typography, TypographyVariant } from 'src/components-dummy';
import { CustomInspirationsGallery, CustomInspirationsGalleryImage } from 'src/services';
import { useValidateSchema } from 'src/hooks';
import { Dispatch } from '../../../types';
import { tagImageModalActions } from './Actions';
import { TaggableImage } from './components';
import {
  CloseIconStyled,
  DialogModalWithPaginationContentStyled,
  DialogModalWithPaginationStyled,
  TagImageModalContentStyled,
  TagImageModalHeaderDividerStyled,
  TagImageModalHeaderStyled,
} from './TagImageModal.styles';
import { ImageSettings } from './components/ImageSettings';
import { galleryImageSettingsValidationSchema } from './constants';

interface TagImageModalProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  gallery: CustomInspirationsGallery;
  selectedImageId: string;
}

export function TagImageModal({
  shopId,
  dispatch,
  gallery,
  selectedImageId,
  permittedRouteMap,
}: TagImageModalProps): JSX.Element {
  const imagesToRender = useMemo(() => gallery.images, [gallery.images]);

  const selectedImageIndex = useMemo(() => {
    return imagesToRender.findIndex(image => image.id === selectedImageId);
  }, [imagesToRender, selectedImageId]);

  const selectedImage = useMemo(
    () => ({ ...imagesToRender[selectedImageIndex] }),
    [imagesToRender, selectedImageIndex]
  );

  const [imageDraft, setImageDraft] = useState(selectedImage);
  const [isDirty, setIsDirty] = useState(false);
  const [failedToLoadImage, setFailedToLoadImage] = useState(false);

  const { errors, isValid, validate, resetValidationState } = useValidateSchema<
    Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>
  >({
    initialData: selectedImage,
    schema: galleryImageSettingsValidationSchema,
    validateOnStart: false,
  });

  const onImageErrorStatusChange = useCallback(
    ({ hasError }: { hasError: boolean }) => {
      setFailedToLoadImage(hasError);
    },
    [setFailedToLoadImage]
  );

  const resetDraft = useCallback(() => {
    setImageDraft({ ...selectedImage });
    resetValidationState();
  }, [selectedImage]);

  useEffect(() => {
    resetDraft();
  }, [resetDraft]);

  const onChange = useCallback(
    (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => {
      setImageDraft({ ...imageDraft, ...partialImage });
    },
    [imageDraft, setImageDraft]
  );

  const removeTagById = useCallback(
    (id: string) => {
      const updatedTags = imageDraft.tags.filter(tag => tag.id !== id);
      const updatedAiSuggestedTags = imageDraft.aiSuggestedTags.filter(tag => tag.id !== id);

      onChange({ tags: updatedTags, aiSuggestedTags: updatedAiSuggestedTags });
    },
    [onChange, imageDraft]
  );

  const startImageTagsDetection = useCallback(() => {
    dispatch(
      tagImageModalActions.startImageTagsDetection({
        galleryId: gallery.id,
        imageId: selectedImageId,
        shopId,
      })
    );
  }, [shopId, dispatch, gallery.id, selectedImageId]);

  useEffect(() => {
    const imageDraftUndefinedRemoved = omitBy(imageDraft, isUndefined);
    const isDraftEqualToSource = isEqual(selectedImage, imageDraftUndefinedRemoved);
    setIsDirty(!isDraftEqualToSource);
  }, [imageDraft, setIsDirty]);

  useEffect(() => {
    dispatch(tagImageModalActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  useEffect(() => {
    validate({ dataToValidate: imageDraft });
  }, [imageDraft, validate]);

  const navigateToPreviewGallery = useCallback(() => {
    if (!permittedRouteMap.editCustomInspirationsGallery) {
      return;
    }

    dispatch(
      tagImageModalActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.editCustomInspirationsGallery.path, {
          shopId,
          galleryId: gallery.id,
        }),
      })
    );
  }, [shopId, gallery.id, permittedRouteMap.editCustomInspirationsGallery, dispatch]);

  const hasPreviousImage = selectedImageIndex !== 0;
  const hasNextImage = selectedImageIndex !== imagesToRender.length - 1;

  const onPaginationButtonClick = useCallback(
    ({ isNext }: { isNext: boolean }) =>
      () => {
        const targetImageIndex = isNext ? selectedImageIndex + 1 : selectedImageIndex - 1;

        const targetImage: CustomInspirationsGalleryImage | undefined =
          imagesToRender[targetImageIndex];

        if (targetImage && permittedRouteMap.tagImageInCustomInspirationsGallery) {
          dispatch(
            tagImageModalActions.navigateTo({
              navigateTo: generatePath(permittedRouteMap.tagImageInCustomInspirationsGallery.path, {
                shopId,
                galleryId: gallery.id,
                imageId: targetImage.id,
              }),
            })
          );
        }
      },
    [
      shopId,
      gallery.id,
      selectedImageIndex,
      imagesToRender,
      dispatch,
      permittedRouteMap.tagImageInCustomInspirationsGallery,
    ]
  );

  const updateGalleryImage = useCallback(
    async (image: CustomInspirationsGalleryImage): Promise<void> => {
      const updatedGalleryImages = [...gallery.images];
      updatedGalleryImages[selectedImageIndex] = image;
      (
        dispatch(
          tagImageModalActions.updateGallery({
            shopId,
            galleryId: gallery.id,
            payload: { images: updatedGalleryImages },
          })
        ) as any
      ).unwrap();
    },
    [
      gallery.id,
      gallery.images,
      shopId,
      selectedImageIndex,
      isValid,
      hasNextImage,
      dispatch,
      onPaginationButtonClick,
      navigateToPreviewGallery,
    ]
  );

  const onApplyAiSuggestedTags = useCallback(async () => {
    if (!selectedImage || !imageDraft) return;

    const updatedImage = {
      ...selectedImage,
      tags: [
        ...selectedImage.tags,
        ...imageDraft.aiSuggestedTags.map(tag => ({ ...tag, isAIDetected: true })),
      ],
      aiSuggestedTags: [],
    };
    try {
      await updateGalleryImage(updatedImage);
    } catch (error) {
      console.log(error);
    }
  }, [imageDraft, selectedImage, updateGalleryImage]);

  const onSave = useCallback(async () => {
    if (!isValid) return;
    try {
      await updateGalleryImage(imageDraft);

      dispatch(tagImageModalActions.notifyIsDirty({ isDirty: false }));

      if (hasNextImage) {
        onPaginationButtonClick({ isNext: true })();
      } else {
        navigateToPreviewGallery();
      }
    } catch (error) {
      console.error(error);
    }
  }, [imageDraft, updateGalleryImage]);

  return (
    <DialogModalWithPaginationStyled
      open
      onNextButtonClick={hasNextImage ? onPaginationButtonClick({ isNext: true }) : undefined}
      onBackButtonClick={hasPreviousImage ? onPaginationButtonClick({ isNext: false }) : undefined}
      onClose={navigateToPreviewGallery}
      disableArrowKeys
    >
      <DialogModalWithPaginationContentStyled>
        <TagImageModalHeaderStyled>
          <Typography variant={TypographyVariant.LargeMedium}>Image General Settings</Typography>
          <CloseIconStyled name={AvailableIcons.Close} onClick={navigateToPreviewGallery} />
        </TagImageModalHeaderStyled>
        <TagImageModalHeaderDividerStyled />
        <TagImageModalContentStyled>
          <ImageSettings
            image={imageDraft}
            isDirty={isDirty}
            isValid={isValid}
            failedToLoadImage={failedToLoadImage}
            errors={errors}
            onChange={onChange}
            onSave={onSave}
            resetDraft={resetDraft}
            removeTagById={removeTagById}
          />
          <TaggableImage
            image={imageDraft}
            onChange={onChange}
            shopId={shopId}
            dispatch={dispatch}
            onImageErrorStatusChange={onImageErrorStatusChange}
            removeTagById={removeTagById}
            showTags
            startImageTagsDetection={startImageTagsDetection}
            onApplyAiSuggestedTags={onApplyAiSuggestedTags}
          />
        </TagImageModalContentStyled>
      </DialogModalWithPaginationContentStyled>
    </DialogModalWithPaginationStyled>
  );
}
