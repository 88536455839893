import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const pinSize = 32;

export const ProductPin = styled.div`
  width: ${pinSize}px;
  height: ${pinSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const ProductPinOuterCircle = styled.div<{ isApplied: boolean; isAIDetected: boolean }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  opacity: 0.3;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;

  ${({ theme, isApplied, isAIDetected }) =>
    isApplied === false && isAIDetected
      ? `border: ${theme.palette.custom['primary-main']} dashed 2px;`
      : ''}
`;

export const ProductPinInnerCircle = styled.div<{ isApplied: boolean; isAIDetected: boolean }>`
  background-color: ${({ theme, isAIDetected }) =>
    isAIDetected ? theme.palette.custom['primary-light'] : theme.palette.common.white};
  width: 50%;
  height: 50%;
  border-radius: 50%;
  position: absolute;

  ${({ theme, isApplied, isAIDetected }) =>
    isApplied === false && isAIDetected
      ? `border: ${theme.palette.custom['primary-main']} dashed 2px;`
      : ''}
`;

export const TagWrapper = styled.div<{ isApplied: boolean; isAIDetected: boolean }>`
  height: 32px;
  padding: 8px;
  max-width: 276px;
  background-color: ${({ theme, isAIDetected, isApplied }) =>
    isAIDetected && isApplied === false
      ? theme.palette.custom['primary-light']
      : theme.palette.common.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 8px;

  ${({ theme, isAIDetected, isApplied }) =>
    isAIDetected && isApplied === false ? `color: ${theme.palette.custom['primary-main']}` : ''}
`;

export const TagIconStyled = styled(Icon)<{ isAIDetected: boolean; isApplied: boolean }>`
  min-width: 12px;
  max-width: 12px;

  path {
    stroke: ${({ theme, isAIDetected, isApplied }) =>
      isAIDetected && isApplied === false
        ? theme.palette.custom['primary-main']
        : theme.palette.custom['gray-10']};
  }
`;

export const AiTagIconStyled = styled(Icon)<{ isApplied: boolean }>`
  min-width: 12px;
  max-width: 12px;

  path {
    fill: ${({ theme, isApplied }) =>
      isApplied ? theme.palette.custom['gray-10'] : theme.palette.custom['primary-main']};
  }
`;

export const TagTypographyStyled = styled(Typography)``;
