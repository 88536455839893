import React from 'react';
import {
  NumericInput,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';
import './RecentlyViewedSettings.scss';
import { RecentlyViewedSettingsProps } from './types';
import { useRecentlyViewedSettingsDraft } from './useRecentlyViewedSettingsDraft';
import { createOptionalPortal } from '../helpers';
import { ConfigurationNote } from '../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../components-dummy/Labels/configuration-variant';
import { AddToCartForm } from '../AddToCartSettings/AddToCartForm';
import { useAppSelector } from '../../hooks';

export function RecentlyViewedSettings({
  shopId,
  recentlyViewedSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: RecentlyViewedSettingsProps): JSX.Element | null {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);
  const recentlyViewed = useRecentlyViewedSettingsDraft({
    recentlyViewedSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const { draftRecentlyViewedSettings, isValid, isDirty, onSubmit, onDiscard, onChange, errors } =
    recentlyViewed;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  return (
    <div className='recently-viewed-settings-page'>
      <div className='recently-viewed-settings-container-wrapper'>
        <div className='recently-viewed-settings-container'>
          <div className='recently-viewed-days'>
            <span className='recently-viewed-days-title'>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                Show items that were viewed in last
              </Typography>
            </span>
            <NumericInput
              error={!!errors.numberOfRecentDaysToDisplay}
              value={draftRecentlyViewedSettings.numberOfRecentDaysToDisplay}
              onChange={value => {
                onChange({ numberOfRecentDaysToDisplay: value });
              }}
              className='recently-viewed-days-input'
            />
            <span className='recently-viewed-days-title'>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                days (maximum 90 days)
              </Typography>
            </span>
          </div>
          <div className='previously-purchased-toggle-wrapper'>
            <Switch
              checked={draftRecentlyViewedSettings.showPreviouslyPurchasedItems}
              onChange={() => {
                onChange({
                  showPreviouslyPurchasedItems:
                    !draftRecentlyViewedSettings.showPreviouslyPurchasedItems,
                });
              }}
              className='previously-purchased-toggle'
            >
              <Switch.TitleTemplate className='previously-purchased-toggle-title'>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                  Show previously purchased items
                </Typography>
              </Switch.TitleTemplate>
            </Switch>
          </div>
          <div className='out-of-stock-toggle-wrapper'>
            <Switch
              checked={draftRecentlyViewedSettings.showOutOfStockItems}
              onChange={() => {
                onChange({
                  showOutOfStockItems: !draftRecentlyViewedSettings.showOutOfStockItems,
                });
              }}
              className='out-of-stock-toggle'
            >
              <Switch.TitleTemplate className='out-of-stock-toggle-title'>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                  Show out of stock items
                </Typography>
              </Switch.TitleTemplate>
            </Switch>
          </div>
          <div className='recently-viewed-limit'>
            <span className='recently-viewed-limit-title'>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                Results Limit (3-30)
              </Typography>
            </span>
            <NumericInput
              error={!!errors.numberOfResultsToDisplay}
              value={draftRecentlyViewedSettings.numberOfResultsToDisplay}
              onChange={value => {
                onChange({ numberOfResultsToDisplay: value });
              }}
              className='recently-viewed-limit-input'
            />
          </div>
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.allAboveToAllClients} />
      <AddToCartForm
        showSettingsForm={addToCartEnabled}
        addToCart={draftRecentlyViewedSettings.addToCart}
        onChange={addToCart => onChange({ addToCart })}
        shopId={shopId}
      />
      {formButtonsSection}
    </div>
  );
}
