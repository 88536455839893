import { createAction } from '@reduxjs/toolkit';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';
import {
  ImageTagsDetectionEndedEventData,
  ImageTagsDetectionStartedEventData,
} from 'src/services/src/service/galleries';

export const useGalleriesWSActions = {
  imageTagsDetectionStarted: createAction<ImageTagsDetectionStartedEventData>(
    'UseGalleriesWS/ImageTagsDetectionStarted'
  ),
  imageTagsDetectionEnded: createAction<ImageTagsDetectionEndedEventData>(
    'UseGalleriesWS/ImageTagsDetectionEnded'
  ),
  notification: createAction<{ customMessage: string }>('UseGalleriesWS/Notification'),
  errorNotification: createAction<{ error: { errorKey: ValidationErrorKey } }>(
    'UseGalleriesWS/ErrorNotification'
  ),
};
