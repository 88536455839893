import React from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { TitleTypographyStyled, ActionButtonStyled } from '../../Login/Login.styles';

export const ResetPasswordFormSuccess = ({
  goToLogin,
  isCreatingInitialUserPassword,
}: {
  goToLogin: () => void;
  isCreatingInitialUserPassword: boolean;
}): JSX.Element => {
  return (
    <>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        {isCreatingInitialUserPassword
          ? 'Your password is created'
          : 'Password has been changed successfully'}
      </TitleTypographyStyled>
      <ActionButtonStyled onClick={goToLogin}>Back to login</ActionButtonStyled>
    </>
  );
};
